import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import Button from '../../../components/Button/Button';
import { DaysOfWeek, Offer, OfferCategory, OfferStatus } from '../../../graphql/API';
import { datetimeWithTimezone } from '../../../utils/dateV2';
import Highlight from './Highlight';

const dayOrder = Object.values(DaysOfWeek);

type OfferCardProps = {
  data: Offer;
};

const OfferCard: React.FC<OfferCardProps> = ({ data }) => {
  const navigate = useNavigate();

  const onOfferNavigation = () => {
    navigate(`/offers/${data.id}`);
  };

  const { startDatetime, endDatetime } = React.useMemo(
    () => ({
      startDatetime: datetimeWithTimezone({
        date:
          data.status === OfferStatus.active
            ? dayjs.utc(data?.startDateTime).format('YYYY-MM-DD')
            : data?.startDate,
        time:
          data.status === OfferStatus.active
            ? dayjs.utc(data?.startDateTime).format('HH:mm')
            : data?.startTime,
        timezoneId: data?.timeZoneId,
      }).tz(),
      endDatetime: datetimeWithTimezone({
        date:
          data.status === OfferStatus.active
            ? dayjs.utc(data?.startDateTime).format('YYYY-MM-DD')
            : data?.startDate,
        time: data?.endTime,
        timezoneId: data?.timeZoneId,
      }).tz(),
    }),
    [data],
  );

  const availableDays = [...(data?.availableDays || [])]
    ?.sort((a, b) => dayOrder.indexOf(a as DaysOfWeek) - dayOrder.indexOf(b as DaysOfWeek))
    ?.map((day) => capitalize(day as string))
    .join(', ');

  return (
    <Card
      sx={{
        minHeight: '390px',
        maxHeight: '390px',
        maxWidth: 345,
        position: 'relative',
        cursor: 'pointer',
        boxShadow: '0px 0px 20px 0px #0000000F',
        fontFamily: 'Poppins',
      }}
      onClick={onOfferNavigation}
      key={data.id}
    >
      <Highlight
        privateOffer={data.private || false}
        eventOffer={data.category === OfferCategory.event}
      />
      <CardMedia
        component='img'
        height={200}
        src={String(data.picture?.small)}
        loading='lazy'
        onError={(e) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-expect-error
          e.target.src = String(data.picture?.small);
        }}
      />
      <CardContent sx={{ px: '16px' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>{data.title}</Typography>
        {!data.private && (
          <>
            {data.category === OfferCategory.event && (
              <Typography sx={{ fontSize: '14px', color: 'rgba(17, 17, 17, 0.50)' }}>
                {startDatetime.format('ll')}
              </Typography>
            )}
            {data.category !== OfferCategory.event && (
              <Typography sx={{ fontSize: '14px', color: 'rgba(17, 17, 17, 0.50)' }}>
                Every {availableDays}
              </Typography>
            )}
            <Typography sx={{ fontSize: '14px', color: 'rgba(17, 17, 17, 0.50)' }}>
              {startDatetime.format('LT')} - {endDatetime.format('LT')}
            </Typography>
          </>
        )}
      </CardContent>
      <Box
        sx={{
          px: '14px',
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 13,
        }}
      >
        <Button
          component={Link}
          to={`/offers/${data.id}/edit`}
          aria-label='Edit'
          id='btn-offer-edit'
          variant='contained'
          size='large'
          color='secondary'
          sx={{
            padding: '4px 10px',
            minWidth: 45,
            width: 45,
            height: 45,
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
          }}
        >
          <EditIcon fontSize='small' />
        </Button>

        {data.private ? (
          <Button
            component={Link}
            variant='outlined'
            size='large'
            fullWidth
            to={`/collabs?collabId=${data.id}`}
            sx={{ marginLeft: 1, borderWidth: 2, borderColor: 'black' }}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
            }}
          >
            Invite
          </Button>
        ) : (
          <Button
            component={Link}
            variant='outlined'
            size='large'
            fullWidth
            to={`/offers/${data.id}`}
            sx={{ marginLeft: 1, borderWidth: 2, borderColor: 'black' }}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
            }}
          >
            Offer details
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default OfferCard;
