import React from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Stack, Typography } from '@mui/material';

import { Formik, FormikConfig } from 'formik';

import Button from '../../components/Button/Button';
import { Method } from './OfferForm';
import { Form1 } from './components/FormSteps/Forms/Form1';
import { Form2 } from './components/FormSteps/Forms/Form2';
import { Form3 } from './components/FormSteps/Forms/Form3';
import { Form4 } from './components/FormSteps/Forms/Form4';
import { FormValue, schema } from './components/schema';

type OfferFormProps = {
  formikConfig: FormikConfig<FormValue>;
  method: Method;
};

export const EditOfferForm: React.FC<OfferFormProps> = ({
  formikConfig: { initialValues, onSubmit },
  method = 'update',
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        py: 4,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
      }}
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        {({ values, handleSubmit, touched, errors, setValues }) => {
          return (
            <Box
              onSubmit={handleSubmit}
              component='form'
              maxWidth='sm'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
                flexGrow: 1,
                m: 'auto',
              }}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                aria-label='back'
                color='primary'
                onClick={handleBack}
                sx={{ fontSize: '24px', fontWeight: 600, mb: 4 }}
              >
                Edit Offer
              </Button>
              <Stack spacing={6} width={'100%'}>
                <Box pb={7} borderBottom={'1px solid #E6E6E6'}>
                  <Form1 values={values} method={'update'} initialValues={initialValues} />
                </Box>
                {values.type === 'public' && (
                  <>
                    <Box pb={5} borderBottom={'1px solid #E6E6E6'} my={4}>
                      <Typography sx={{ fontSize: '24px', fontWeight: 600, mb: 5 }}>
                        Date and time
                      </Typography>
                      <Form2
                        values={values}
                        method={method}
                        initialValues={initialValues}
                        handleAddress={(location) => {
                          if (location) {
                            setValues((prev) => ({
                              ...prev,
                              location: {
                                lat: location?.location.lat,
                                lon: location?.location.lon,
                              },
                              address: location?.formattedAddress,
                            }));
                          } else {
                            setValues((prev) => ({
                              ...prev,
                              location: null,
                              address: '',
                            }));
                          }
                        }}
                        addressFieldError={touched.address && Boolean(errors.address)}
                        addressFieldHelperText={touched.address ? errors.address : ''}
                      />
                    </Box>
                    <Box pb={5} borderBottom={'1px solid #E6E6E6'} my={4}>
                      <Typography sx={{ fontSize: '24px', fontWeight: 600, mb: 5 }}>
                        Requirements
                      </Typography>
                      <Form3 values={values} method={method} initialValues={initialValues} />
                    </Box>

                    <Form4 values={values} method={method} initialValues={initialValues} />
                  </>
                )}

                <Button fullWidth variant='contained' type='submit' sx={{ px: '5rem', py: '1rem' }}>
                  Update offer
                </Button>
              </Stack>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};
