import dayjs, { Dayjs } from 'dayjs';

type OptionalString = string | null;

type DateTimeParams = {
  datetime?: OptionalString;
  date?: OptionalString;
  time?: OptionalString;
  timezoneId?: OptionalString;
};

type DateTimeTemplate = 'll' | 'YYYY-MM-DD' | 'HH:mm' | 'LT' | 'HH:mm A';

const convertLocalToUTC = (localDateTime: dayjs.Dayjs) => {
  return localDateTime.utc();
};

const utils = (djs?: Dayjs, timezoneId?: OptionalString) => ({
  dayjs: () => djs,
  toDate: () =>
    djs && timezoneId
      ? new Date(djs?.toDate().toLocaleString('en-US', { timeZone: timezoneId }))
      : undefined,
  format: (f: DateTimeTemplate, defaultValue?: string) => djs?.format(f) ?? defaultValue,
});

export const timeToDayJs = (time: string) => dayjs.utc(time, 'HH:mm');

export const datetimeWithTimezone = (params: DateTimeParams) => {
  const { datetime, date, time, timezoneId } = params;

  const normalisedDateTime = datetime ?? (date && time ? `${date} ${time}` : null);

  const dt =
    normalisedDateTime && dayjs(normalisedDateTime).isValid() ? normalisedDateTime : undefined;

  return {
    utc: () => {
      const djs = dt && timezoneId ? dayjs.tz(dt, timezoneId).utc() : undefined;

      return utils(djs, timezoneId);
    },
    tz: () => {
      const djs = dt && timezoneId ? dayjs.utc(dt).tz(timezoneId) : undefined;

      return utils(djs, timezoneId);
    },
  };
};

const getDateTimeFromTime = (date: string, time: string) =>
  dayjs.utc(date).format(`YYYY-MM-DD ${time.slice(0, 5)}`);

const convertUTCToLocal = (utcDateTime: dayjs.Dayjs, timeZoneId: string) => {
  const localDateTime = utcDateTime.tz(timeZoneId);

  return localDateTime;
};

export const getTimeInTimezone = (
  date: string,
  time?: string,
  timeZoneId?: Nullable<string>,
): string | undefined =>
  date && time && timeZoneId && dayjs(getDateTimeFromTime(date, time)).isValid()
    ? convertUTCToLocal(dayjs.utc(getDateTimeFromTime(date, time)), timeZoneId).format('HH:mm')
    : undefined;

export const toUtcTime = (time: string, timezoneId?: string): string =>
  datetimeWithTimezone({
    datetime: timeToDayJs(time).format('YYYY-MM-DD HH:mm'),
    timezoneId,
  })
    .utc()
    .format('HH:mm') ?? '';

export const getUTCTimeFromTimeInTimezone = (
  date: string,
  time?: string,
  timeZoneId?: Nullable<string>,
): string =>
  date && time && timeZoneId && dayjs(getDateTimeFromTime(date, time)).isValid()
    ? convertLocalToUTC(dayjs.tz(getDateTimeFromTime(date, time), timeZoneId)).format('HH:mm')
    : '00:00';

export const getUTCDateAndTimeFromTimeInTimezone = (
  date: string,
  time?: string,
  timeZoneId?: Nullable<string>,
): Dayjs | undefined =>
  date && time && timeZoneId && dayjs(getDateTimeFromTime(date, time)).isValid()
    ? convertLocalToUTC(dayjs.tz(getDateTimeFromTime(date, time), timeZoneId))
    : undefined;

export const getTimeInTimezoneInDayjs = (
  date: string,
  time?: string,
  timeZoneId?: Nullable<string>,
): Dayjs | undefined =>
  date && time && timeZoneId && dayjs(getDateTimeFromTime(date, time)).isValid()
    ? convertUTCToLocal(dayjs.utc(getDateTimeFromTime(date, time)), timeZoneId)
    : undefined;

export const getDateAndTimeInTimezoneInDayjs = (
  date?: string,
  timeZoneId?: Nullable<string>,
): Dayjs | undefined =>
  date && timeZoneId && dayjs(date).isValid()
    ? convertUTCToLocal(dayjs.utc(date), timeZoneId)
    : undefined;
