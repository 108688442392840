import * as React from 'react';

import { Avatar, Box, BoxProps, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { environment } from '../../config';
import usePortfolioQuery from '../../pages/Profile/components/usePortfolioQuery';
import Icons from '../../themes/icons';

type PhotoCardProps = BoxProps<'label'> & {
  error?: boolean;
};

const baseUrl = (() => {
  const envUrl = environment === 'production' ? '' : `-${environment}`;
  return `https://assets${envUrl}.beautypass.app`;
})();

const PhotoCard = styled(Box, { shouldForwardProp: (prop) => prop !== 'error' })<PhotoCardProps>(
  () => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#F7F7F7',
    width: '100%',
    cursor: 'pointer',
    minHeight: '100%',
  }),
);

export const PortfolioPhoto: React.FC = () => {
  const { uploadMutation } = usePortfolioQuery();
  const [previewImg, setPreviewImg] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>();


  const handleFileUpload = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      const file = event.target.files ? event.target.files[0] : undefined;

      if (file) {
        setLoading(true);
        const dataUrl = URL.createObjectURL(file);
        setPreviewImg(dataUrl);

        await uploadMutation.mutateAsync(file);

        setLoading(false);
        setPreviewImg('');
      }
    },
    [uploadMutation],
  );

  return (
    <PhotoCard component='label'>
      {previewImg && (
        <Avatar
          src={previewImg}
          sx={{
            position: 'absolute',
            borderRadius: 'inherit',
            height: '100%',
            width: '100%',
            opacity: loading ? 0.5 : 1,
          }}
        />
      )}
      {!previewImg && !loading && <Icons.AddIcon />}
      {loading && <CircularProgress size={20} />}
      <input hidden accept='image/*' type='file' onChange={handleFileUpload} disabled={loading} />
    </PhotoCard>
  );
};
