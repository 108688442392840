import dayjs from 'dayjs';
import { pick } from 'lodash';
import * as yup from 'yup';

import {
  DaysOfWeek,
  FoodCategories,
  Gender,
  LocationInput,
  Offer,
  OfferAccessType,
  OfferCategory,
  OfferStatus,
  Profession,
  VenueCreateOfferInput,
  VenueUpdateOfferInput,
} from '../../../graphql/API';
import {
  datetimeWithTimezone,
  getTimeInTimezone,
  getUTCTimeFromTimeInTimezone,
} from '../../../utils/dateV2';

export const OFFER_TYPES = ['public', 'private'] as const;
export const OFFER_CATEGORIES = Object.values(OfferCategory);
export const OFFER_FOOD_CATEGORIES = Object.values(FoodCategories);
export const DAYS_OF_WEEK = Object.values(DaysOfWeek);
export const OFFER_ACCESS_TYPES = Object.values(OfferAccessType);

const VALID_REQUIREMENTS = [
  'postOnInstagramStories',
  'postOnInstagramStories3x',
  'postOnInstagramFeed',
  'postOnInstagramReels',
] as const;

const REQUIREMENTS = ['noRequirement', ...VALID_REQUIREMENTS] as const;

export type OfferType = (typeof OFFER_TYPES)[number];
export type Requirement = (typeof REQUIREMENTS)[number];

export const waitTimeOptions = [
  { label: 'Everyday', value: 0 },
  { label: '3 times per week', value: 2 },
  { label: '1 time per week', value: 6 },
  { label: '1 time every two weeks', value: 13 },
  { label: '1 time per month', value: 30 },
  { label: '1 time every two months', value: 60 },
  { label: '1 time per year', value: 364 },
  { label: '1 time only', value: 10000 },
];

export const waitTimeOptionsV2 = (repeatDaysLength: number) => [
  { label: 'Unlimited', value: 0, premium: false, disabled: false },
  { label: '3 times per week', value: 2, premium: false, disabled: repeatDaysLength < 3 },
  { label: '1 time per week', value: 6, premium: false, disabled: false },
  { label: '1 time per month', value: 30, premium: false, disabled: false },
  { label: '1 time only', value: 10000, premium: true, disabled: false },
];

export const schema = yup.object({
  type: yup.mixed<OfferType>().oneOf(OFFER_TYPES).required(),
  offerImageKey: yup.string().required(),
  category: yup.mixed<OfferCategory>().oneOf(OFFER_CATEGORIES).required(),
  subCategory: yup
    .mixed<FoodCategories>()
    .oneOf(OFFER_FOOD_CATEGORIES)
    .when('category', {
      is: 'food',
      then: (schema) => schema.required(),
    })
    .nullable(),
  title: yup.string().required(),
  description: yup.string().max(800).required(),
  spots: yup
    .number()
    .when('type', {
      is: 'public',
      then: (schema) => schema.required().min(0),
    })
    .nullable(),
  waitTime: yup
    .number()
    .when(['type', 'category'], {
      is: (type: unknown, category: unknown) => type === 'public' && category !== 'event',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),
  offerValue: yup.number().required().min(1, 'Offer value must be greater than or equal to 1'),
  minFollowers: yup
    .number()
    .when('type', {
      is: 'public',
      then: (schema) => schema.required().min(0),
    })
    .nullable(),
  availableDays: yup
    .array(yup.mixed<DaysOfWeek>().oneOf(DAYS_OF_WEEK).required())
    .when(['type', 'category'], {
      is: (type: unknown, category: unknown) => type === 'public' && category !== 'event',
      then: (schema) => schema.required().min(1),
      otherwise: (schema) => schema.nullable(),
    }),
  // .nullable(),
  startDate: yup.date().required(),
  endDate: yup
    .date()
    .nullable()
    .when(['startDate', 'category'], ([startDate, category], schema) => {
      if (startDate && category !== 'event') {
        return schema.min(startDate, 'End date must be greater then start date');
      }
      return schema;
    }),
  startTime: yup
    .date()
    .when('type', {
      is: 'public',
      then: (schema) => schema.required(),
    })
    .test({
      name: 'notSameAsEndTime',
      message: 'Start time and end time cannot be the same.',
      test: function (startTime) {
        const endTime: Date = this.resolve(yup.ref('endTime'));
        const type: string = this.resolve(yup.ref('type'));
        // If either startTime or endTime is not available, validation passes
        if (!startTime || !endTime || type == 'private') {
          return true;
        }
        // Compare the values
        return startTime.getTime() !== endTime.getTime();
      },
    }),
  endTime: yup
    .date()
    .when('type', {
      is: 'public',
      then: (schema) => schema.required(),
    })
    .test({
      name: 'notSameAsStartTime',
      message: 'End time and start time cannot be the same.',
      test: function (endTime) {
        const startTime: Date = this.resolve(yup.ref('startTime'));
        const type: string = this.resolve(yup.ref('type'));
        // If either endTime or startTime is not available, validation passes
        if (!endTime || !startTime || type == 'private') {
          return true;
        }
        // Compare the values
        return endTime.getTime() !== startTime.getTime();
      },
    }),
  requirements: yup.mixed<Requirement>().oneOf(REQUIREMENTS).required(),
  dressCode: yup.string().max(50, 'Dress code must be at most 50 characters'),
  suggestedTip: yup.number().min(0).nullable(),
  confirmWithCall: yup.boolean().notRequired(),
  selectMembersAccess: yup
    .mixed<OfferAccessType>()
    .when('type', {
      is: 'public',
      then: (schema) => schema.oneOf(OFFER_ACCESS_TYPES),
    })
    .test({
      name: 'rejectAll',
      exclusive: true,
      message: 'Cannot be "Reject All" at the same time as "Lifestyle Select".',
      test: function (value) {
        const lifestyleMembersAccess = this.resolve(yup.ref('lifestyleMembersAccess'));
        return !(
          value === OfferAccessType.rejectAll &&
          lifestyleMembersAccess === OfferAccessType.rejectAll
        );
      },
    }),

  lifestyleMembersAccess: yup
    .mixed<OfferAccessType>()
    .when('type', {
      is: 'public',
      then: (schema) => schema.oneOf(OFFER_ACCESS_TYPES),
    })
    .test({
      name: 'rejectAll',
      exclusive: true,
      message: 'Cannot be "Reject All" at the same time as "BeautyPass Select".',
      test: function (value) {
        const selectMembersAccess = this.resolve(yup.ref('selectMembersAccess'));
        return !(
          value === OfferAccessType.rejectAll && selectMembersAccess === OfferAccessType.rejectAll
        );
      },
    }),
  location: yup
    .object()
    .shape({
      lat: yup.number(),
      lon: yup.number(),
    })
    .when('category', {
      is: 'event',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),
  address: yup
    .string()
    .when('category', {
      is: 'event',
      then: (schema) => schema.required(),
    })
    .nullable(),
});

export type FormValue = yup.InferType<typeof schema>;

const dateNow = new Date();

export const initialValues: FormValue = {
  type: 'public',
  offerImageKey: '',
  category: OfferCategory.beauty,
  title: '',
  description: '',
  spots: 1,
  waitTime: 0,
  offerValue: 0,
  minFollowers: 0,
  startDate: dateNow,
  endDate: null,
  startTime: dayjs('09:00', 'HH:mm').toDate(),
  endTime: dayjs('17:00', 'HH:mm').toDate(),
  availableDays: [],
  requirements: 'postOnInstagramStories',
  dressCode: '',
  suggestedTip: 0,
  location: null,
  address: '',
  lifestyleMembersAccess: OfferAccessType.acceptAll,
  selectMembersAccess: OfferAccessType.acceptAll,
};

export const dateToDateString = (date: Date, time: Date) => {
  const dDate = dayjs(date);
  const dTime = dayjs(time);

  const dDateTime = dDate.hour(dTime.hour()).minute(dTime.minute());

  return dDateTime.format('YYYY-MM-DD HH:mm');
};

export const mapToOfferForm = (data: Offer): FormValue => {
  const req = VALID_REQUIREMENTS.find((x) => !!data[x]) ?? 'noRequirement';

  const startDateV2 =
    data.status === OfferStatus.active
      ? dayjs.utc(data?.startDateTime).format('YYYY-MM-DD')
      : data?.startDate;
  const startTimeV2 =
    data.status === OfferStatus.active
      ? dayjs.utc(data?.startDateTime).format('HH:mm')
      : data?.startTime;

  const startDateTime = datetimeWithTimezone({
    date: startDateV2,
    time: startTimeV2,
    timezoneId: data.timeZoneId,
  }).tz();
  const endDateTime = datetimeWithTimezone({
    date: data.endDate ?? data.startDate,
    time: data.endTime,
    timezoneId: data.timeZoneId,
  }).tz();

  const _start = getTimeInTimezone(startDateV2, startTimeV2, data?.timeZoneId);
  const _end = getTimeInTimezone(startDateV2, data?.endTime, data?.timeZoneId);

  return {
    requirements: req,
    offerImageKey: data.picture?.medium,
    type: data.private ? 'private' : 'public',
    startDate: startDateTime?.toDate(),
    startTime: dayjs(`${data.startDate} ${_start}`).toDate() ?? null,
    endTime: dayjs(`${data.startDate} ${_end}`).toDate() ?? null,
    ...(data.category !== 'event' && { endDate: data.endDate ? endDateTime.toDate() : null }),
    ...pick(data, [
      'confirmWithCall',
      'category',
      'title',
      'description',
      'availableDays',
      'dressCode',
      'suggestedTip',
      'offerValue',
      'spots',
      'waitTime',
      'minFollowers',
      'selectMembersAccess',
      'lifestyleMembersAccess',
      'location',
      'address',
      'subCategory',
    ]),
  } as FormValue;
};

const callToConfirmAllowCategory: OfferCategory[] = [
  OfferCategory.beauty,
  OfferCategory.fitness,
  OfferCategory.experience,
  OfferCategory.boutique,
];

export const isAllowCallToConfirm = (isOnReg: boolean, category: OfferCategory): boolean =>
  isOnReg && callToConfirmAllowCategory.includes(category);

const mapToReqInput = (value: FormValue): Partial<VenueCreateOfferInput> => ({
  postOnInstagramFeed: null,
  postOnInstagramStories: null,
  postOnInstagramReels: null,
  postOnInstagramStories3x: null,
  ...(value.requirements !== 'noRequirement' ? { [value.requirements]: true } : {}),
});

const mapToCommonOfferInput = (
  values: FormValue,
  timezoneId: string | null,
): Omit<VenueCreateOfferInput, 'venueID'> => {
  const isPublic = values.type === 'public';

  const spots = values.spots ?? 0;
  const offerValue = values.offerValue ?? 0;

  const utcDTStart = datetimeWithTimezone({
    datetime: dateToDateString(values.startDate, values.startTime ?? new Date()),
    timezoneId,
  }).utc();
  const req = mapToReqInput(values);

  const _dayjsStartTime = getUTCTimeFromTimeInTimezone(
    utcDTStart.format('YYYY-MM-DD') || '',
    dayjs(values.startTime).format('HH:mm') ?? '',
    timezoneId ?? undefined,
  );
  const _dayjsEndTime = getUTCTimeFromTimeInTimezone(
    utcDTStart.format('YYYY-MM-DD') || '',
    dayjs(values.endTime).format('HH:mm') ?? '',
    timezoneId ?? undefined,
  );

  return {
    startDate: utcDTStart.format('YYYY-MM-DD') ?? '',
    startTime: _dayjsStartTime ?? '',
    endTime: _dayjsEndTime ?? '',
    status: OfferStatus.pending,
    availableTo: [Profession.model],
    gender: [Gender.female],
    spots: isPublic ? spots : 1000,
    waitTime: isPublic ? values.waitTime : null,
    offerValue: offerValue,
    minFollowers: isPublic ? values.minFollowers : null,
    offerImageKey: values.offerImageKey.startsWith('https://') ? undefined : values.offerImageKey,
    suggestedTip: values.suggestedTip ? String(values.suggestedTip) : null,
    confirmWithCall: isAllowCallToConfirm(values.requirements !== 'noRequirement', values.category)
      ? values.confirmWithCall
      : null,
    location: values.location ? (values.location as LocationInput) : null,
    ...req,
    ...pick(values, [
      'category',
      'title',
      'description',
      'availableDays',
      'dressCode',
      'selectMembersAccess',
      'lifestyleMembersAccess',
      'address',
      'subCategory',
    ]),
  };
};

export const mapToCreateOfferInput = (
  venueID: string,
  values: FormValue,
  timezoneId?: string | null,
): VenueCreateOfferInput => {
  const isPublic = values.type === 'public';
  const commonInput = mapToCommonOfferInput(values, timezoneId ?? null);

  return {
    venueID,
    private: !isPublic,
    ...commonInput,
    ...(!isPublic && { startTime: '00:00', endTime: '00:00' }),
  };
};

export const mapToUpdateOfferInput = (
  id: string,
  values: FormValue,
  timezoneId?: string | null,
): VenueUpdateOfferInput => {
  const isPublic = values.type === 'public';
  const commonInput = mapToCommonOfferInput(values, timezoneId ?? null);
  return {
    id,
    offerImageKey: values.offerImageKey.startsWith('https://') ? undefined : values.offerImageKey,
    ...commonInput,
    ...(!isPublic && { startTime: '00:00', endTime: '00:00' }),
  };
};

export const stepOneSchema = yup.object({
  type: yup.mixed<OfferType>().oneOf(OFFER_TYPES).required(),
  offerImageKey: yup.string().required(),
  category: yup.mixed<OfferCategory>().oneOf(OFFER_CATEGORIES).required(),
  subCategory: yup
    .mixed<FoodCategories>()
    .oneOf(OFFER_FOOD_CATEGORIES)
    .when('category', {
      is: 'food',
      then: (schema) => schema.required(),
    })
    .nullable(),
  title: yup.string().required(),
  description: yup.string().max(800).required(),
  offerValue: yup.number().required().min(1, 'Offer value must be greater than or equal to 1'),
});

export const stepTwoSchema = yup.object({
  spots: yup
    .number()
    .when('type', {
      is: 'public',
      then: (schema) => schema.required().min(0),
    })
    .nullable(),
  waitTime: yup
    .number()
    .when(['type', 'category'], {
      is: (type: unknown, category: unknown) => type === 'public' && category !== 'event',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),
  availableDays: yup
    .array(yup.mixed<DaysOfWeek>().oneOf(DAYS_OF_WEEK).required())
    .when(['type', 'category'], {
      is: (type: unknown, category: unknown) => type === 'public' && category !== 'event',
      then: (schema) => schema.required().min(1),
      otherwise: (schema) => schema.nullable(),
    }),
  // .nullable(),
  startDate: yup.date().required(),
  endDate: yup
    .date()
    .nullable()
    .when(['startDate', 'category'], ([startDate, category], schema) => {
      if (startDate && category !== 'event') {
        return schema.min(startDate, 'End date must be greater then start date');
      }
      return schema;
    }),
  startTime: yup
    .date()
    .when('type', {
      is: 'public',
      then: (schema) => schema.required(),
    })
    .test({
      name: 'notSameAsEndTime',
      message: 'Start time and end time cannot be the same.',
      test: function (startTime) {
        const endTime: Date = this.resolve(yup.ref('endTime'));
        const type: string = this.resolve(yup.ref('type'));
        // If either startTime or endTime is not available, validation passes
        if (!startTime || !endTime || type == 'private') {
          return true;
        }
        // Compare the values
        return startTime.getTime() !== endTime.getTime();
      },
    }),
  endTime: yup
    .date()
    .when('type', {
      is: 'public',
      then: (schema) => schema.required(),
    })
    .test({
      name: 'notSameAsStartTime',
      message: 'End time and start time cannot be the same.',
      test: function (endTime) {
        const startTime: Date = this.resolve(yup.ref('startTime'));
        const type: string = this.resolve(yup.ref('type'));
        // If either endTime or startTime is not available, validation passes
        if (!endTime || !startTime || type == 'private') {
          return true;
        }
        // Compare the values
        return endTime.getTime() !== startTime.getTime();
      },
    }),
  location: yup
    .object()
    .shape({
      lat: yup.number(),
      lon: yup.number(),
    })
    .when('category', {
      is: 'event',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })
    .nullable(),
  address: yup
    .string()
    .when('category', {
      is: 'event',
      then: (schema) => schema.required(),
    })
    .nullable(),
});

export const stepThreeSchema = yup.object({
  minFollowers: yup
    .number()
    .when('type', {
      is: 'public',
      then: (schema) => schema.required().min(0),
    })
    .nullable(),
  requirements: yup.mixed<Requirement>().oneOf(REQUIREMENTS).required(),
  dressCode: yup.string().max(50, 'Dress code must be at most 50 characters'),
  suggestedTip: yup
    .number()
    .min(0, 'Suggested tip cannot be negative')
    .nullable()
    .test('max-tip', 'Suggested tip must be 20% or less of the offer value', function (value) {
      const { offerValue } = this.parent;

      // Allow null or empty input
      if (value == null) return true;

      // Ensure offerValue exists and validate the 20% rule
      return offerValue && value <= 0.2 * offerValue;
    }),
  confirmWithCall: yup.boolean().notRequired(),
  offerValue: yup.number().min(1, 'Offer value must be greater than or equal to 1'),
});

export const stepFourSchema = yup.object({
  selectMembersAccess: yup
    .mixed<OfferAccessType>()
    .when('type', {
      is: 'public',
      then: (schema) => schema.oneOf(OFFER_ACCESS_TYPES),
    })
    .test({
      name: 'rejectAll',
      exclusive: true,
      message: 'Cannot be "Reject All" at the same time as "Lifestyle Select".',
      test: function (value) {
        const lifestyleMembersAccess = this.resolve(yup.ref('lifestyleMembersAccess'));
        return !(
          value === OfferAccessType.rejectAll &&
          lifestyleMembersAccess === OfferAccessType.rejectAll
        );
      },
    }),
  lifestyleMembersAccess: yup
    .mixed<OfferAccessType>()
    .when('type', {
      is: 'public',
      then: (schema) => schema.oneOf(OFFER_ACCESS_TYPES),
    })
    .test({
      name: 'rejectAll',
      exclusive: true,
      message: 'Cannot be "Reject All" at the same time as "BeautyPass Select".',
      test: function (value) {
        const selectMembersAccess = this.resolve(yup.ref('selectMembersAccess'));
        return !(
          value === OfferAccessType.rejectAll && selectMembersAccess === OfferAccessType.rejectAll
        );
      },
    }),
});

export type StepOneFormValue = yup.InferType<typeof stepOneSchema>;
export type StepTwoFormValue = yup.InferType<typeof stepTwoSchema>;
export type StepThreeFormValue = yup.InferType<typeof stepThreeSchema>;
export type StepFourFormValue = yup.InferType<typeof stepFourSchema>;
