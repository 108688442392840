import React from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { useModal } from 'mui-modal-provider';

import ManageAccessTipsDialog from '../../../../../components/Dialogs/ManageAccessTips/ManageAccessTipsDialog';
import SelectOption from '../../../../../components/Formiks/SelectOption/SelectOption';
import useSubscriptionAccess from '../../../../../hooks/useSubscriptionAccess';
import { memberAccessOptions } from '../../../../../utils/offerAccess';
import { Method } from '../../../OfferForm';
import SectionTitle from '../../SectionTitle';
import SideBySide from '../../SideBySide';
import {
  StepFourFormValue,
  StepOneFormValue,
  StepThreeFormValue,
  StepTwoFormValue,
} from '../../schema';

type Values = StepOneFormValue & StepTwoFormValue & StepThreeFormValue & StepFourFormValue;

type Props = {
  method?: Method;
  initialValues?: Values;
  values: Values;
};

export const Form4: React.FC<Props> = ({ method = 'insert', initialValues, values }) => {
  const subscriptionAccess = useSubscriptionAccess();
  const { showModal } = useModal();

  const handleClickTips = React.useCallback(() => {
    showModal(ManageAccessTipsDialog, { title: 'Set access for your offers' });
  }, [showModal]);

  const noPremiumAccess = React.useMemo(
    () => !subscriptionAccess?.includes('PREMIUM_ACCESS_CONTROL'),
    [subscriptionAccess],
  );

  return (
    <Box>
      <SectionTitle
        title={
          <Box>
            Manage Access
            <IconButton size='small' color='primary' sx={{ ml: 1 }} onClick={handleClickTips}>
              <BsInfoCircleFill />
            </IconButton>
          </Box>
        }
      />

      <SideBySide>
        <CustomBox>
          <label htmlFor='slt-access-select' className='beautipass-offer-form-label'>
            Beautypass Select
          </label>
          <SelectOption
            disabled={noPremiumAccess}
            id='slt-access-select'
            name='selectMembersAccess'
            options={memberAccessOptions.slice(0, 2)}
            fullWidth
            sx={{ padding: '5px 0' }}
          />
        </CustomBox>
        <CustomBox>
          <label htmlFor='slt-access-lifestyle' className='lifestyle-offer-form-label'>
            Lifestyle
          </label>
          <SelectOption
            id='slt-access-lifestyle'
            name='lifestyleMembersAccess'
            options={memberAccessOptions}
            fullWidth
            sx={{ padding: '5px 0' }}
          />
        </CustomBox>
      </SideBySide>
      {noPremiumAccess && (
        <Typography variant='caption' component='div' sx={{ mt: 2 }}>
          <Stack spacing={1} direction='row'>
            <Box component='img' src='/png/premium.png' sx={{ height: '1rem', fill: '#429C12' }} />
            <Box component='span' sx={{ fontSize: '15px', fontWeight: '500' }}>
              Premium Feature
            </Box>
            <Box
              component={Link}
              to='/plans'
              sx={{ color: '#111111', fontSize: '15px', fontWeight: '600' }}
            >
              Get Premium
            </Box>
          </Stack>
        </Typography>
      )}
    </Box>
  );
};

const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  fieldset {
    border: 1px solid #e6e6e6 !important;
  }
`;
