import * as React from 'react';
import { Link } from 'react-router-dom';

import { Box, Stack, SvgIconProps, Typography } from '@mui/material';

import styled from '@emotion/styled';

import Tooltip from '../../../components/Dialogs/CollabsFilters/ToolTip';
import SvgIcon from '../../../components/Icons';
import { OfferCategory, Venue, VenuePlan } from '../../../graphql/API';
import { formatNumber } from '../../../utils/formatter';
import { VENUE_MANUAL_TAGS } from '../../../utils/venue-tags';

type DashboardCardProps = {
  label: string;
  count: number;
  Icon: React.FC<SvgIconProps>;
  subscriptionPlan: string;
  onFavouritesHandle: () => void;
  venueType?: OfferCategory;
  venueTags?: Venue['tags'];
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  label,
  count,
  subscriptionPlan = '',
  onFavouritesHandle,
  venueTags,
}) => (
  <Box display={'flex'}>
    <Stack spacing={1} alignSelf={'end'}>
      <Typography variant='h5' fontWeight='500'>
        {formatNumber(count)}
      </Typography>
      <Typography
        variant='body2'
        noWrap
        sx={
          label.toLowerCase() === 'favorites' && +formatNumber(count) > 0
            ? {
                textDecoration: 'underline',
                cursor: 'pointer',
              }
            : {}
        }
        {...(label.toLowerCase() === 'favorites' &&
        ((venueTags?.includes(VENUE_MANUAL_TAGS.BrandPlans) && subscriptionPlan !== VenuePlan.basic) ||
          (!venueTags?.includes(VENUE_MANUAL_TAGS.BrandPlans) && subscriptionPlan)) &&
        +formatNumber(count) > 0
          ? {
              onClick: onFavouritesHandle,
            }
          : {})}
      >
        {label.toLowerCase() === 'favorites' &&
        ((venueTags?.includes(VENUE_MANUAL_TAGS.BrandPlans) && subscriptionPlan === VenuePlan.basic) ||
          (!venueTags?.includes(VENUE_MANUAL_TAGS.BrandPlans) && !subscriptionPlan)) &&
        +formatNumber(count) > 0 ? (
          <Tooltip
            arrow
            title={
              <React.Fragment>
                <PremiumBox>
                  <PremiumBoxSidebar></PremiumBoxSidebar>
                  <TitleBox>
                    To unlock this feature, upgrade your plan to
                    <SvgIcon
                      name='Plus'
                      sx={{
                        fill: 'url(#linear-gradient)',
                        padding: '6px 0 0 0',
                        margin: '0px 6px',
                      }}
                      fontSize='small'
                    />
                    <Link
                      style={{
                        fontSize: '15px',
                        color: '#E28DDE',
                        fontWeight: 600,
                        textDecoration: 'underline',
                      }}
                      to={'/plans'}
                    >
                      Plus
                    </Link>
                  </TitleBox>
                </PremiumBox>
              </React.Fragment>
            }
          >
            <span>{label}</span>
          </Tooltip>
        ) : (
          <span>{label}</span>
        )}
      </Typography>
    </Stack>
  </Box>
);

export default DashboardCard;

const PremiumBox = styled.div`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
`;

const PremiumBoxSidebar = styled.div`
  height: inherit;
  width: 12px;
  border-radius: 5px;
  background-image: linear-gradient(to bottom right, #b26ff3, #c991ff);
`;

const TitleBox = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: #000;
  padding: 0px 20px;
`;
