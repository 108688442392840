import React from 'react';

import { Box, Stack } from '@mui/material';

import { Formik } from 'formik';

import Button from '../../../../components/Button/Button';
import { StepOneFormValue, StepTwoFormValue, stepTwoSchema } from '../schema';
import { Form2 } from './Forms/Form2';
import './style.css';

type Props = {
  data: StepTwoFormValue & StepOneFormValue;
  handleNext: (values: StepTwoFormValue & StepOneFormValue) => void;
};

export const Step2: React.FC<Props> = ({ data, handleNext }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={stepTwoSchema}
      onSubmit={(values) => {
        handleNext(values);
      }}
    >
      {({ values, handleSubmit, setValues, errors, touched }) => {
        return (
          <Box component={'form'} onSubmit={handleSubmit}>
            <Stack spacing={6} width={'100%'}>
              <Form2
                values={values}
                handleAddress={(location) => {
                  if (location) {
                    setValues((prev) => ({
                      ...prev,
                      location: {
                        lat: location?.location.lat,
                        lon: location?.location.lon,
                      },
                      address: location?.formattedAddress,
                    }));
                  } else {
                    setValues((prev) => ({
                      ...prev,
                      location: null,
                      address: '',
                    }));
                  }
                }}
                addressFieldError={touched.address && Boolean(errors.address)}
                addressFieldHelperText={touched.address ? errors.address : ''}
              />
              <Button fullWidth variant='contained' type='submit' sx={{ px: '5rem', py: '1rem' }}>
                Continue
              </Button>
            </Stack>
          </Box>
        );
      }}
    </Formik>
  );
};
