import * as React from 'react';

import { useModal } from 'mui-modal-provider';

import OfferSuccessDialog from '../../components/Dialogs/OfferSuccess/OfferSuccessDialog';
import { BackdropLoading } from '../../contexts/Backgrop/Backdrop';
import { OfferCategory } from '../../graphql/API';
import { useCreateOfferQuery } from '../../hooks/reactQuery/useOfferQuery';
import useQueryParams from '../../hooks/useQueryParams';
import { useAppSelector } from '../../redux/hooks';
import OfferForm from './OfferForm';
import { FormValue, initialValues, mapToCreateOfferInput } from './components/schema';

const CreateOffer: React.FC = () => {
  const { getQueryParam } = useQueryParams();
  const offerType = getQueryParam('type');
  const venueState = useAppSelector((state) => state.venue.value);

  const { showModal } = useModal();

  const mutation = useCreateOfferQuery();

  return (
    <>
      <OfferForm
        method='insert'
        formConfig={{
          initialValues: {
            ...initialValues,
            ...(offerType === 'collab' ? { category: OfferCategory.unknown, type: 'private' } : {}),
          },
          onSubmit: async (values: FormValue) => {
            if (venueState) {
              const input = mapToCreateOfferInput(venueState.id, values, venueState.timeZoneId);

              await mutation.mutateAsync(input);

              showModal(OfferSuccessDialog, {});
            }
          },
        }}
        offerTypeIndex={offerType === 'collab' ? 1 : -1}
      />
      {mutation.isPending && <BackdropLoading open />}
    </>
  );
};

export default CreateOffer;
