import * as React from 'react';

import { Box, Chip, ChipProps, Stack, TooltipProps } from '@mui/material';

type Icon = ChipProps['icon'];

type Tooltip = {
  Component: React.ComponentType<TooltipProps>;
  props: Omit<TooltipProps, 'children'>;
};

export type ChipOption = {
  value: string;
  label: string;
  disabled?: boolean;
  icon?: Icon;
  tooltip?: Tooltip;
};

type OptionProps = {
  label: string;
  value: string;
  selected: boolean;
  disabled?: boolean;
  icon?: Icon;
  tooltip?: Tooltip;
  onClick: (value: string) => void;
};

export type ChipOptionsProps = {
  name: string;
  defaultValue?: string;
  options: ChipOption[];
  onChange: (params: { name: string; value: string }) => void;
};

const Option: React.FC<OptionProps> = ({
  label,
  value,
  selected,
  icon,
  disabled,
  onClick,
  tooltip,
}) => {
  const renderChild = React.useCallback(
    () => (
      <Chip
        icon={icon}
        label={label}
        variant='outlined'
        color={disabled ? 'default' : 'primary'}
        onClick={() => (!disabled && !selected ? onClick(value) : undefined)}
        style={{
          padding: '20px 28px',
          borderRadius: '40px'
        }}
        sx={{
          ...(selected ? { border: '1px solid #111', fontWeight: 600 } : { color: '#848484', border: '1px solid' }),
          fontSize: '15px',
        }}
      />
    ),
    [label, value, selected, icon, disabled, onClick],
  );

  return tooltip ? (
    <tooltip.Component {...tooltip.props}>{renderChild()}</tooltip.Component>
  ) : (
    renderChild()
  );
};

export const ChipOptionsV2: React.FC<ChipOptionsProps> = ({
  name,
  options,
  onChange,
  defaultValue = '',
}) => {
  const [value, setValue] = React.useState<string>();

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClick = React.useCallback(
    (v: string) => {
      setValue(v);
      onChange({ name, value: v });
    },
    [name, setValue, onChange],
  );

  const selected = React.useCallback((v: string | undefined) => value === v, [value]);

  return (
    <Box display={'flex'} flexWrap={'wrap'} gap={'16px'}>
      {options.map(({ value, ...restProps }) => (
        <Option
          {...restProps}
          key={value}
          value={value}
          selected={selected(value)}
          onClick={handleClick}
        />
      ))}
    </Box>
  );
};
