import * as React from 'react';

import Box from '@mui/material/Box';

import styled from '@emotion/styled';
import { useField } from 'formik';
import { isEqual, sortBy, upperFirst } from 'lodash';

import { DaysOfWeek } from '../../../graphql/API';
import { Method } from '../OfferForm';
import { AttentionError } from './AttentionError';
import { CheckboxGroupForAvailableDays, OptionItem } from './CheckboxGroupForAvailableDays';
import { FormValue, StepOneFormValue, StepTwoFormValue } from './schema';

const daysOfWeek: DaysOfWeek[] = [
  DaysOfWeek.monday,
  DaysOfWeek.tuesday,
  DaysOfWeek.wednesday,
  DaysOfWeek.thursday,
];

const daysOfWeekTwo: DaysOfWeek[] = [DaysOfWeek.friday, DaysOfWeek.saturday, DaysOfWeek.sunday];

const options = daysOfWeek.map<OptionItem>((x) => ({
  label: upperFirst(x),
  value: x,
}));

const optionsV2 = daysOfWeekTwo.map<OptionItem>((x) => ({
  label: upperFirst(x),
  value: x,
}));

type Props = {
  method?: Method;
  initialValues?: StepOneFormValue & StepTwoFormValue;
};

export const AvailableDays: React.FC<Props> = ({ method = 'insert', initialValues }) => {
  const [availableDaysField] = useField('availableDays');
  return (
    <Box>
      <Label>On which days does it repeat?</Label>
      <CheckboxGroupForAvailableDays
        optionsOne={options}
        optionsTwo={optionsV2}
        name='availableDays'
      />

      {initialValues &&
        method === 'update' &&
        !isEqual(sortBy(initialValues.availableDays), sortBy(availableDaysField.value)) && (
          <AttentionError />
        )}
    </Box>
  );
};

const Label = styled.p`
  color: #000;
  font-size: 15px;
  font-weight: 500;
`;
