import React from 'react';

import { Box } from '@mui/material';

import { Formik } from 'formik';

import Button from '../../../../components/Button/Button';
import { StepOneFormValue, StepThreeFormValue, StepTwoFormValue, stepThreeSchema } from '../schema';
import { Form3 } from './Forms/Form3';
import './style.css';

type Props = {
  data: StepThreeFormValue & StepTwoFormValue & StepOneFormValue;
  handleNext: (values: StepThreeFormValue & StepTwoFormValue & StepOneFormValue) => void;
};

export const Step3: React.FC<Props> = ({ data, handleNext }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={stepThreeSchema}
      onSubmit={(values) => {
        handleNext(values);
      }}
    >
      {({ values, handleSubmit }) => {
        return (
          <Box component={'form'} onSubmit={handleSubmit}>
            <Form3 values={values} />
            <Box mt={6}>
              <Button fullWidth variant='contained' type='submit' sx={{ px: '5rem', py: '1rem' }}>
                Continue
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};
