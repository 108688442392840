import * as React from 'react';
import { Link } from 'react-router-dom';

import { Box, Card, CardActions, CardContent } from '@mui/material';

import Button from '../../../components/Button/Button';
import SvgIcon from '../../../components/Icons';

const AddOffer: React.FC = () => {
  return (
    <Card
      sx={{
        minHeight: '390px',
        maxHeight: '390px',
        maxWidth: 345,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px dashed #eee',
        background: 'transparent',
        p: '8px',
        boxShadow: '0px 0px 20px 0px #0000000F',
        fontFamily: 'Poppins'
      }}
    >
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box display='flex'>
          <SvgIcon name='NewOffer' inheritViewBox sx={{ fontSize: 100 }} />
        </Box>
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          to='/offers/create'
          variant='contained'
          fullWidth
          sx={{ borderRadius: '8px' }}
        >
          Create new offer
        </Button>
      </CardActions>
    </Card>
  );
};

export default AddOffer;
