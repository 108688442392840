import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import Grid, { GridProps } from '@mui/material/Grid';

import Button from '../../../components/Button/Button';
import { User } from '../../../graphql/API';
import useSubscriptionAccess from '../../../hooks/useSubscriptionAccess';
import Icons from '../../../themes/icons';
import ProfileCard from './ProfileCard';
import ProfileCardSkeleton from './ProfileCardSkeleton';
import { OnChange } from './types';

type ProfileCheckBoxGroupProps = {
  data: User[];
  isLoading: boolean;
  isFetching: boolean;
  onChange: (values: string[]) => void;
  values: string[];
  handleMarketChange?: () => void;
};

const commonGrid = (key: string): GridProps => ({
  item: true,
  key,
  xs: 2.4,
  md: 3,
  lg: 3,
  xl: 2.4,
});

const ProfileCheckBoxGroup: React.FC<ProfileCheckBoxGroupProps> = ({
  data,
  isLoading,
  isFetching,
  onChange,
  values,
  handleMarketChange,
}) => {
  const subscriptionAccess = useSubscriptionAccess();

  const handleOnChanged = React.useCallback<OnChange>(
    (e, checked) => {
      const v = e.target.value;

      const vv = checked ? [...values, v] : values.filter((y) => y !== v);
      onChange(vv);
    },
    [onChange, values],
  );

  return (
    <>
      <Grid container spacing={3} rowSpacing={10}>
        {isLoading
          ? [...Array(6)].map((_, i) => (
              <Grid {...commonGrid(`grid-skeleton-${i}`)} key={`skeleton-${i}`}>
                <ProfileCardSkeleton />
              </Grid>
            ))
          : data.map((x) => (
              <Grid {...commonGrid(x.id)} key={x.id}>
                <ProfileCard
                  data={x}
                  fullProfile={subscriptionAccess?.includes('COMPLETE_USER_PROFILES')}
                  onChange={handleOnChanged}
                  checked={values.includes(x.id)}
                />
              </Grid>
            ))}
      </Grid>
      {!isFetching && data.length === 0 && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          height={'calc(100vh - 320px)'}
        >
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'48px'}>
            <Icons.NoMemberIcon />
            <Stack spacing={4} alignItems={'center'}>
              <Box textAlign={'center'}>
                <Typography fontSize={'24px'} fontWeight={600} color={'#222'}>
                  No member found…
                </Typography>
                <Typography fontSize={'15px'} fontWeight={500} color='#222'>
                  Try changing your filters and search again.
                </Typography>
              </Box>
              <Button
                variant='outlined'
                sx={{ py: '10px', px: '24px' }}
                onClick={handleMarketChange}
              >
                Search all markets
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProfileCheckBoxGroup;
