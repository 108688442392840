import * as React from 'react';

import { Box } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useField } from 'formik';

const FormikNumberField: React.FC<TextFieldProps> = ({ name, id, className = '', ...props }) => {
  const [field, meta, helper] = useField(name || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;

    // Allow only positive numbers or a single decimal point
    const regex = /^[0-9]+$/;

    const _value = value.trim();
    if (_value === '' || (regex.test(_value) && Number(_value) > 0)) {
      helper.setValue(_value === '' ? 0 : Number(_value));
    }
  };

  return (
    <Box position='relative'>
      <TextField
        type='text'
        variant='outlined'
        size='small'
        fullWidth
        id={id}
        name={field.name}
        value={field.value}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched ? meta.error : ''}
        onChange={handleChange}
        onBlur={field.onBlur}
        className={className}
        {...props}
      />
    </Box>
  );
};

export default FormikNumberField;
