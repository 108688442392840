import * as React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  InputAdornment,
  LinearProgress,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';

import { debounce } from 'lodash';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { UserAccessRequestResultValue, VenueListAccessRequetsFilter } from '../../../graphql/API';
import { useVenueListAccessRequestsCountQuery } from '../../../hooks/reactQuery/accessRequest/useVenueListAccessRequestsCountQuery';
import useAccessRequestQuery from '../../../hooks/reactQuery/useAccessRequestQuery';
import { AccessRequestPageView } from '../../../pages/AccessRequest/AccessRequest';
import { UserCategory, mapUserCategory } from '../../../utils/user';
import Button from '../../Button/Button';
import ChipOptions from '../../ChipOptions/ChipOptions';
import { AccessRequestGrid } from '../../Grids/AccessRequest/AccessRequest';
import SvgIcon from '../../Icons';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import useColumn from './Column';
import { useAction } from './useAction';

type AccessRequestTableProps = {
  view: AccessRequestPageView;
  height?: string;
  filters?: Partial<VenueListAccessRequetsFilter>;
  handleClickList: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const AccessRequestTable: React.FC<AccessRequestTableProps> = ({
  view,
  height,
  filters: defaultFilters,
  handleClickList,
}) => {
  const [filters, setFilters] = React.useState<Partial<VenueListAccessRequetsFilter> | undefined>({
    ...defaultFilters,
    status: UserAccessRequestResultValue.requested,
  });

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useAccessRequestQuery(filters);
  const { data: userCategoriesCountsData } = useVenueListAccessRequestsCountQuery({
    status: filters?.status,
    searchText: filters?.searchText,
  });

  const actionProps = useAction();

  const columns = useColumn(actionProps);

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && hasNextPage) {
          fetchNextPage();
        }
      }
    },
    [isFetching, fetchNextPage, hasNextPage],
  );

  const renderTopToolBar = React.useCallback(
    () => !isLoading && isFetching && <LinearProgress />,
    [isLoading, isFetching],
  );

  const table = useMaterialReactTable({
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    muiTableHeadProps: { sx: { opacity: 1 } },
    muiTableHeadRowProps: {
      sx: { backgroundColor: 'white', boxShadow: 'none' },
    },
    muiTableBodyRowProps: {
      sx: { backgroundColor: 'inherit' },
    },
    muiTableBodyCellProps: {
      sx: ({ custom }) => ({ borderColor: custom.colors.brandLightGrey }),
    },
    muiTableHeadCellProps: {
      sx: ({ custom }) => ({
        color: 'text.secondary',
        borderColor: custom.colors.brandLightGrey,
      }),
    },
    muiTablePaperProps: { sx: { boxShadow: 'none' } },
    muiTableContainerProps: {
      sx: { height, maxHeight: height, backgroundColor: 'white' },
      onScroll: (event) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    renderBottomToolbar: renderTopToolBar,
    columns,
    data,
    rowVirtualizerOptions: { overscan: 5 },
    state: { isLoading },
    renderEmptyRowsFallback: () => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component='img'
            src='/png/access-empty@2x.png'
            sx={{ marginTop: 10, marginBottom: 2, width: '100px' }}
          />
          <Typography fontWeight='medium'>No access requests yet</Typography>
          <Typography variant='caption'>
            When users request access to your offers, you’ll see them listed here
          </Typography>
        </Box>
      );
    },
  });

  const handleSearch = debounce((value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchText: value,
    }));
  }, 500);

  const handleChange = React.useCallback(
    (value?: string) => {
      handleSearch(value);
    },
    [handleSearch],
  );

  const userCategoryOptions = [
    { label: `All (${userCategoriesCountsData?.totalAll || 0})`, value: '' },
    {
      value: 'true',
      label: `${mapUserCategory[UserCategory.lifestyle]} (${
        userCategoriesCountsData?.totalBPLifestyle || 0
      })`,
    },
    {
      value: 'false',
      label: `${mapUserCategory[UserCategory.select]} (${
        userCategoriesCountsData?.totalBPSelect || 0
      })`,
    },
  ];

  return (
    <Stack spacing={4}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems={'flex-start'}
        sx={{ paddingY: '8px' }}
      >
        <ChipOptions
          name='userCategory'
          options={userCategoryOptions}
          onChange={({ value: v }) => {
            setFilters((prevFilters) => {
              const lifestyleValue = !v ? undefined : v === 'true' ? true : false;
              return { ...prevFilters, lifestyle: lifestyleValue };
            });
          }}
        />
        <Box display={'flex'} alignItems={'flex-start'} gap={'8px'}>
          <OutlinedInput
            id='outlined-adornment-amount'
            placeholder='Search a member...'
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            size='small'
            sx={{
              paddingY: '0px',
            }}
          />
          <Button
            id='btn-access-list'
            variant='contained'
            size='small'
            startIcon={<SvgIcon name='InviteSent' sx={{ fontSize: '20px' }} />}
            onClick={handleClickList}
            sx={{
              paddingY: '8px',
            }}
          >
            Access list
          </Button>
        </Box>
      </Box>

      {view === 'list' ? (
        <MaterialReactTable table={table} />
      ) : (
        <Box sx={{ overflowY: 'auto', height: height, maxHeight: height }}>
          <InfiniteScroll
            isLoading={isLoading}
            isFetching={isFetching}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          >
            <AccessRequestGrid data={data} isLoading={isLoading} />
          </InfiniteScroll>
        </Box>
      )}
    </Stack>
  );
};

export default AccessRequestTable;
