import { useEffect } from 'react';
import 'react-aspect-ratio/aspect-ratio.css';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Authenticator, Flex } from '@aws-amplify/ui-react';
import { Amplify, Auth } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import 'photoswipe/dist/photoswipe.css';

import './authenticator.css';
import { NotiAlertV2 } from './components/NotiAlertV2/NotiAlertV2';
import awsExports from './config/aws-exports';
import AnalyticsProvider from './contexts/AnalyticsContext/provider';
import { AppConfigProvider } from './contexts/AppConfigContext/provider';
import BackdropProvider from './contexts/Backgrop/Backdrop';
import { ChatProvider } from './contexts/ChatContext/Chat';
import router from './router';
import { themeOptions } from './themes/default.theme';

Amplify.configure(awsExports);

const theme = createTheme(themeOptions);

function App() {
  useEffect(() => {
    Amplify.configure({
      ...awsExports,
      API: {
        graphql_headers: async () => {
          try {
            const session = await Auth.currentSession();
            return {
              Authorization: session.getIdToken().getJwtToken(),
            };
          } catch (err) {
            console.log(err);
          }
        },
      },
    });
  }, []);

  return (
    <Flex direction='column' justifyContent='center' height='100vh'>
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <AppConfigProvider>
            <AnalyticsProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <BackdropProvider>
                  <ChatProvider>
                    <SnackbarProvider
                      Components={{
                        success: NotiAlertV2,
                        warning: NotiAlertV2,
                        error: NotiAlertV2,
                      }}
                    >
                      <RouterProvider router={router} />
                    </SnackbarProvider>
                  </ChatProvider>
                </BackdropProvider>
              </LocalizationProvider>
            </AnalyticsProvider>
          </AppConfigProvider>
        </Authenticator.Provider>
      </ThemeProvider>
    </Flex>
  );
}

export default App;
