import React from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';

import styled from '@emotion/styled';
import { useField } from 'formik';

import { StarterIcon } from '../../../components/Dialogs/CollabsFilters/ToolTip';
import useSubscriptionAccess from '../../../hooks/useSubscriptionAccess';
import Icons from '../../../themes/icons';
import { StepOneFormValue, StepTwoFormValue, waitTimeOptionsV2 } from './schema';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    minWidth: '340px',
  },
}));

type Values = StepOneFormValue & StepTwoFormValue;

type Props = {
  values: Values;
};

export const OfferRepeat: React.FC<Props> = ({ values }) => {
  const subscriptionAccess = useSubscriptionAccess();

  const noPremiumAccess = React.useMemo(
    () => !subscriptionAccess?.includes('PREMIUM_ACCESS_CONTROL'),
    [subscriptionAccess],
  );

  const [field, , helper] = useField<string>('waitTime');

  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const v = e.currentTarget.value as string;
      helper.setValue(v);
    },
    [helper],
  );

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} gap={'10px'} mb={2}>
        <Label htmlFor='offer-repeat'>How often can each member redeem this offer?</Label>
        <HtmlTooltip
          placement='top'
          arrow
          title={
            <Box>
              <Typography sx={{ fontSize: '15px', color: '#000', p: '16px' }}>
                Limit how often the same member can redeem this offer
              </Typography>
            </Box>
          }
        >
          <Icons.InfoIcon />
        </HtmlTooltip>
      </Box>
      <RadioGroup
        id='offer-repeat'
        aria-labelledby='offer-repeat-radios'
        name='waitTime'
        value={field.value}
        onChange={handleChange}
      >
        {waitTimeOptionsV2(values.availableDays?.length || 0).map((option) =>
          option.premium ? (
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={
                <Box display={'flex'} alignItems={'center'} gap={'12px'}>
                  <span>{option.label}</span>
                  {noPremiumAccess && (
                    <Stack spacing={1} direction='row'>
                      <StarterIcon />
                      <Box
                        component={Link}
                        sx={{
                          fontSize: '15px',
                          fontWeight: '500',
                          textDecoration: 'underline',
                          color: '#000',
                        }}
                        to='/plans'
                      >
                        Premium Feature
                      </Box>
                    </Stack>
                  )}
                </Box>
              }
              disabled={noPremiumAccess}
            />
          ) : (
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
              disabled={option.disabled}
            />
          ),
        )}
      </RadioGroup>
    </Box>
  );
};

const Label = styled.label`
  color: #000;
  font-size: 15px;
  font-weight: 500;
`;
