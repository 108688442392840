import React from 'react';

import { Box, Stack } from '@mui/material';

import styled from '@emotion/styled';
import { isEqual } from 'lodash';

import TimePicker from '../../../../../components/Formiks/CustomTimePicker/CustomTimePicker';
import DatePicker, {
  SlotProps as DateSlotProps,
} from '../../../../../components/Formiks/DatePicker/DatePicker';
import FormikTextField from '../../../../../components/Formiks/TextField/TextField';
import GooglePlaceAutoComplete from '../../../../../components/GooglePlaceAutoComplete/GooglePlaceAutoComplete';
import { AddressDetail } from '../../../../../components/GooglePlaceAutoComplete/types';
import { OfferCategory } from '../../../../../graphql/API';
import { useAppSelector } from '../../../../../redux/hooks';
import { Method } from '../../../OfferForm';
import { AttentionError } from '../../AttentionError';
import { AvailableDays } from '../../AvailableDays';
import { AvailableSpots } from '../../AvailableSpots';
import { OfferRepeat } from '../../OfferRepeat';
import SideBySide from '../../SideBySide';
import { StepOneFormValue, StepTwoFormValue } from '../../schema';
import { useField } from 'formik';

type Values = StepOneFormValue & StepTwoFormValue;

type Props = {
  method?: Method;
  initialValues?: Values;
  values: Values;
  handleAddress: (address: AddressDetail | null) => void;
  addressFieldError?: boolean;
  addressFieldHelperText?: string;
};

const slotProps: DateSlotProps = {
  textField: {
    size: 'small',
    fullWidth: true,
  },
};

export const Form2: React.FC<Props> = ({
  initialValues,
  values,
  method = 'insert',
  handleAddress,
  addressFieldError,
  addressFieldHelperText,
}) => {
  const venueState = useAppSelector((state) => state.venue.value);

  const [waitTimeField, , waitTimeHelper] = useField('waitTime');
  const [availableDaysField] = useField('availableDays');

  React.useEffect(() => {
    if (waitTimeField.value == 2 && availableDaysField.value.length === 2) {
      waitTimeHelper.setValue(0)
    }
  }, [availableDaysField.value, waitTimeField.value, waitTimeHelper]);

  return (
    <Stack spacing={6} width={'100%'}>
      {values.category === OfferCategory.event && (
        <CustomBox>
          <GooglePlaceAutoComplete
            type='offer'
            onChange={handleAddress}
            error={addressFieldError}
            helperText={addressFieldHelperText || ''}
            placeAddress={venueState?.address1 || ''}
          />
        </CustomBox>
      )}

      {values.address && (
        <CustomBox>
          <label htmlFor='txt-address' className='offer-form-label'>
            Address
          </label>
          <FormikTextField
            id='txt-address'
            name='address'
            placeholder='Ex. address'
            disabled
            className='offer-input-field'
            value={values.address}
          />
        </CustomBox>
      )}

      <CustomBox>
        <label htmlFor='' className='offer-form-label'>
          {values.category === OfferCategory.event ? 'Date' : 'Start date'}
        </label>
        <DatePicker
          format='D MMMM YYYY'
          name='startDate'
          slotProps={slotProps as DateSlotProps}
          className='offer-input-field'
        />
        {initialValues &&
          method === 'update' &&
          !isEqual(initialValues.startDate, values.startDate) && <AttentionError />}
      </CustomBox>

      {values.category !== OfferCategory.event && (
        <AvailableDays initialValues={initialValues} method={method} />
      )}

      <SideBySide>
        <CustomBox>
          <label htmlFor='timeFrom' className='offer-form-label'>
            Time from
          </label>
          <TimePicker
            name='startTime'
            className='offer-time-input-field'
            sx={{ maxWidth: '100%' }}
            id='timeFrom'
          />
          {initialValues && method === 'update' && initialValues.startTime !== values.startTime && (
            <AttentionError />
          )}
        </CustomBox>
        <CustomBox>
          <label htmlFor='endTime' className='offer-form-label'>
            Time to
          </label>
          <TimePicker
            name='endTime'
            className='offer-time-input-field'
            sx={{ maxWidth: '100%' }}
            id='endTime'
          />
          {initialValues && method === 'update' && initialValues.endTime !== values.endTime && (
            <AttentionError />
          )}
        </CustomBox>
      </SideBySide>
      <Box>
        <AvailableSpots />
        {initialValues && method === 'update' && initialValues.spots != values.spots && (
          <AttentionError />
        )}
      </Box>
      {values.category !== OfferCategory.event && (
        <Box>
          <OfferRepeat values={values} />
          {initialValues && method === 'update' && initialValues.waitTime !== values.waitTime && (
            <AttentionError />
          )}
        </Box>
      )}
    </Stack>
  );
};

const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;
