import * as React from 'react';

import { LinearProgress } from '@mui/material';

import { InfiniteQueryObserverBaseResult } from '@tanstack/query-core';

type InfiniteScrollProps = {
  dummy?: string;
  showLoading?: boolean;
  restrictFetching?: boolean;
} & Pick<
  InfiniteQueryObserverBaseResult,
  'isFetching' | 'isLoading' | 'fetchNextPage' | 'hasNextPage'
>;

const InfiniteScroll: React.FC<React.PropsWithChildren<InfiniteScrollProps>> = ({
  children,
  isLoading,
  isFetching,
  hasNextPage,
  fetchNextPage,
  showLoading = true,
  restrictFetching = false,
}) => {
  const observerTarget = React.useRef(null);

  React.useEffect(() => {
    const target = observerTarget.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isLoading && !isFetching && !restrictFetching) {
          fetchNextPage();
        }
      },
      { rootMargin: '-10px' },
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [observerTarget, isLoading, isFetching, fetchNextPage, hasNextPage, restrictFetching]);

  return (
    <>
      {children}

      {!isLoading && showLoading && isFetching && <LinearProgress sx={{ mt: 2 }} />}

      <div ref={observerTarget}>{!isFetching && <span>&nbsp;</span>}</div>
    </>
  );
};

export default InfiniteScroll;
