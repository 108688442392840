import React from 'react';

import { Box, Stack } from '@mui/material';

import { Formik } from 'formik';

import Button from '../../../../components/Button/Button';
import { StepOneFormValue, stepOneSchema } from '../schema';
import { Form1 } from './Forms/Form1';
import './style.css';

type Props = {
  data: StepOneFormValue;
  handleNext: (values: StepOneFormValue) => void;
};

export const Step1: React.FC<Props> = ({ data, handleNext }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={stepOneSchema}
      onSubmit={(values) => {
        handleNext(values);
      }}
    >
      {({ values, handleSubmit }) => {
        return (
          <Box component={'form'} onSubmit={handleSubmit}>
            <Stack spacing={6} sx={{ width: '100%' }}>
              <Form1 values={values} />
              <Button fullWidth variant='contained' type='submit' sx={{ px: '5rem', py: '1rem' }}>
                {values.type === 'private' ? 'Create offer' : 'Continue'}
              </Button>
            </Stack>
          </Box>
        );
      }}
    </Formik>
  );
};
