import React from 'react';

import { Box, Button } from '@mui/material';

import styled from '@emotion/styled';

import icons from '../../../../themes/icons';

type Props = {
  onCreateOffer: () => void;
};

export const OfferNotFound: React.FC<Props> = ({ onCreateOffer }) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
      sx={{
        height: 'calc(600px - 170px)',
      }}
      gap={'110px'}
    >
      <Box
        display='flex'
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        gap={'48px'}
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <img src='/png/collab-offer-black.png' alt='create-offer' width={'90%'} />
        </Box>
        <TitleNoFound>Create a Collab offer to invite selected users</TitleNoFound>
      </Box>
      <Button
        id='btn-create-offer'
        variant='contained'
        sx={{
          width: 320,
          padding: '12px 0',
          fontSize: '15px',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
        onClick={onCreateOffer}
      >
        <span>Create a collab</span>
      </Button>
    </Box>
  );
};

const TitleNoFound = styled.p`
  font-size: 18px;
  color: #111111;
  font-weight: 600;
`;
