import * as React from 'react';

import { Avatar, Box, BoxProps, Typography, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useField } from 'formik';

import { uploadFileToS3 } from '../../../../../api/files/uploadFileToS3';
import { environment } from '../../../../../config';

type PhotoCardProps = BoxProps<'label'> & {
  error?: boolean;
};

const baseUrl = (() => {
  const envUrl = environment === 'production' ? '' : `-${environment}`;
  return `https://assets${envUrl}.beautypass.app`;
})();

const PhotoCard = styled(Box, { shouldForwardProp: (prop) => prop !== 'error' })<PhotoCardProps>(
  ({ theme, error }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed #939393`,
    borderColor: error ? theme.palette.error.light : undefined,
    borderRadius: '8px',
    backgroundColor: '#FEFEFE',
    width: '100%',
    cursor: 'pointer',
    minHeight: 340,
  }),
);

const OfferPhoto: React.FC = () => {
  const [field, meta, helper] = useField<string>('offerImageKey');
  const [previewImg, setPreviewImg] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>();

  const handleFileUpload = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      const file = event.target.files ? event.target.files[0] : undefined;

      if (file) {
        setLoading(true);
        const dataUrl = URL.createObjectURL(file);
        setPreviewImg(dataUrl);

        const s3Key = await uploadFileToS3(file);
        helper.setValue(s3Key);

        setLoading(false);
      }
    },
    [helper, setPreviewImg],
  );

  React.useEffect(() => {
    if (!field.value) {
      setPreviewImg(undefined);
    }

    if (field.value && field.value.startsWith('https://')) {
      setPreviewImg(field.value);
    } else if (field.value && !field.value.startsWith('https://')) {
      setPreviewImg(`${baseUrl}/${field.value}`)
    }

  }, [field.value]);


  return (
    <PhotoCard component='label' error={meta.touched && Boolean(meta.error)}>
      {previewImg ? (
        <Avatar
          src={previewImg}
          sx={{
            position: 'absolute',
            borderRadius: 'inherit',
            height: '100%',
            width: '100%',
            opacity: loading ? 0.5 : 1,
          }}
        />
      ) : (
        <Typography
          component='div'
          fontWeight='medium'
          sx={{ display: 'flex', textDecoration: 'underline', alignItems: 'center' }}
        >
          <Box component='img' src='/svg/upload.svg' sx={{ height: '2rem', mr: 3 }} />
          Select an eye-catching photo
        </Typography>
      )}
      {loading && <CircularProgress />}
      <input hidden accept='image/*' type='file' onChange={handleFileUpload} />
    </PhotoCard>
  );
};

export default OfferPhoto;
