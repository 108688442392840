import * as React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';

import { useField } from 'formik';

export type OptionItem = {
  label: string;
  value: string | number;
};

type CheckboxProps = {
  optionsOne: OptionItem[];
  optionsTwo: OptionItem[];
} & MuiCheckboxProps;

export const CheckboxGroupForAvailableDays: React.FC<CheckboxProps> = ({
  name,
  id,
  optionsOne,
  optionsTwo,
}) => {
  const [field, meta, helper] = useField<string[]>(name || '');

  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const values = field.value.reduce<Record<string, boolean>>(
        (prev, crr) => ({
          ...prev,
          [crr]: true,
        }),
        {},
      );

      const newValues = { ...values, [event.target.name]: event.target.checked };

      const vv = Object.entries(newValues)
        .map(([k, v]) => (v ? k : undefined))
        .filter(Boolean) as string[];

      helper.setValue(vv);
    },
    [field.value, helper],
  );

  return (
    <FormControl
      id={id}
      component='fieldset'
      variant='standard'
      error={meta.touched && Boolean(meta.error)}
      fullWidth
    >
      <FormGroup sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <Box display={'flex'} flexDirection={'column'}>
          {optionsOne.map((x, i) => (
            <FormControlLabel
              key={i}
              control={
                <MuiCheckbox
                  checked={field.value?.includes(String(x.value))}
                  value={x.value}
                  name={String(x.value)}
                  onChange={handleChange}
                />
              }
              label={
                <Typography sx={{ fontWeight: '400', fontSize: '16px', color: '#242121' }}>
                  {x.label}
                </Typography>
              }
            />
          ))}
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          {optionsTwo.map((x, i) => (
            <FormControlLabel
              key={i}
              control={
                <MuiCheckbox
                  checked={field.value?.includes(String(x.value))}
                  value={x.value}
                  name={String(x.value)}
                  onChange={handleChange}
                />
              }
              label={
                <Typography sx={{ fontWeight: '400', fontSize: '16px', color: '#242121' }}>
                  {x.label}
                </Typography>
              }
            />
          ))}
        </Box>
      </FormGroup>
      {meta.touched && <FormHelperText>Please select at least one day</FormHelperText>}
    </FormControl>
  );
};
