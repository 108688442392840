import * as React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Stack, Typography } from '@mui/material';

import Button from '../../components/Button/Button';
import { Step1 } from './components/FormSteps/Step1';
import { Step2 } from './components/FormSteps/Step2';
import { Step3 } from './components/FormSteps/Step3';
import { Step4 } from './components/FormSteps/Step4';
import { OfferTypes } from './components/OfferTypes/OfferTypes';
import Stepper from './components/Stepper';
import {
  FormValue,
  StepFourFormValue,
  StepOneFormValue,
  StepThreeFormValue,
  StepTwoFormValue,
  initialValues,
} from './components/schema';

export type Method = 'insert' | 'update';

type OfferFormProps = {
  formConfig: { initialValues: FormValue; onSubmit: (values: FormValue) => void };
  method: Method;
  offerTypeIndex?: number;
};

const OfferForm: React.FC<OfferFormProps> = ({ formConfig }) => {
  const scrollDivRef = React.useRef<HTMLDivElement | null>(null);

  const [activeStep, setActiveStep] = React.useState(0);

  const [formData, setFormData] = React.useState<FormValue>(formConfig.initialValues);

  React.useEffect(() => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the div
    }
  }, [activeStep]);

  const handleNextStepOne = (values: StepOneFormValue) => {
    if (values.type === 'private' && activeStep === 0) {
      const _values = { ...formData, ...values };
      return formConfig.onSubmit(_values);
    }

    setFormData((prev) => ({ ...prev, ...values }));
    setActiveStep((prev) => prev + 1);
  };

  const handleNextStepTwo = (values: StepTwoFormValue & StepOneFormValue) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setActiveStep((prev) => prev + 1);
  };

  const handleNextStepThree = (
    values: StepTwoFormValue & StepOneFormValue & StepThreeFormValue,
  ) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setActiveStep((prev) => prev + 1);
  };

  const handleNextStepFour = (
    values: StepTwoFormValue & StepOneFormValue & StepThreeFormValue & StepFourFormValue,
  ) => {
    const _values = { ...formData, ...values };
    return formConfig.onSubmit(_values);
  };

  const handleBackStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  const steps = [
    <Step1 data={formData} handleNext={handleNextStepOne} />,
    <Step2 data={formData} handleNext={handleNextStepTwo} />,
    <Step3 data={formData} handleNext={handleNextStepThree} />,
    <Step4 data={formData} handleNext={handleNextStepFour} />,
  ];

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        py: 4,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
        overflow: 'auto',
        height: '100%',
      }}
      ref={scrollDivRef}
    >
      <Box
        component='div'
        maxWidth='sm'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%',
          m: 'auto',
        }}
      >
        {formData.type !== 'private' && <Stepper activeStep={activeStep} />}

        <Button
          startIcon={<ArrowBackIcon />}
          aria-label='back'
          color='primary'
          sx={{ position: 'absolute', left: '5%', top: '2%' }}
          onClick={handleBackStep}
          disabled={activeStep === 0}
        >
          Back
        </Button>

        <Stack spacing={6} width={'100%'}>
          {activeStep === 0 && (
            <Stack spacing={2}>
              <Typography sx={{ fontWeight: '600', fontSize: '24px' }}>Create a new</Typography>
              <OfferTypes
                resetForm={(type, category) => {
                  setFormData({
                    ...initialValues,
                    type,
                    category,
                    ...(type === 'private' && { requirements: 'noRequirement' }),
                  });
                }}
              />
            </Stack>
          )}
          <Box pb={6}>
            {steps[activeStep]}
            {(activeStep === 3 || (activeStep === 0 && formData.type === 'private')) && (
              <Box
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #F3F3F3',
                  background: '#FAFAFA',
                  p: '24px',
                  fontSize: '15px',
                  mt: '45px'
                }}
              >
                After you submit this offer, Beautypass will review it. Your offer will become
                active once approved.
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default OfferForm;
