import React from 'react';
import { Link } from 'react-router-dom';

import { Box, InputAdornment, Stack, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { useField } from 'formik';

import {
  ChipOption,
  ChipOptionsProps,
  ChipOptionsV2,
} from '../../../../../components/ChipOptions/ChipOptionsV2';
import { CurrencySign } from '../../../../../components/CurrencySign/CurrencySign';
import { StarterIcon } from '../../../../../components/Dialogs/CollabsFilters/ToolTip';
import { followerOptionsV2 } from '../../../../../components/Dialogs/CollabsFilters/options';
import FormikNumberField from '../../../../../components/Formiks/NumberField/NumberField';
import FormikTextField from '../../../../../components/Formiks/TextField/TextField';
import useSubscriptionAccess from '../../../../../hooks/useSubscriptionAccess';
import { useAppSelector } from '../../../../../redux/hooks';
import { Method } from '../../../OfferForm';
import { AttentionError } from '../../AttentionError';
import SideBySide from '../../SideBySide';
import OfferCallToConfirm from '../../formiks/OfferCallToConfirm/OfferCallToConfirm';
import ReqRadioGroup from '../../formiks/OfferRequirement/OfferRequirement';
import {
  StepOneFormValue,
  StepThreeFormValue,
  StepTwoFormValue,
  isAllowCallToConfirm,
} from '../../schema';

type ChipOnChange = ChipOptionsProps['onChange'];

type MinimumFollowersProps = {
  label?: string;
  name?: string;
  defaultValue?: number;
  options: ChipOption[];
};

const MinimumFollowersOptions: React.FC<MinimumFollowersProps> = ({
  label = 'Minimum followers',
  name = 'minFollowers',
  defaultValue = 0,
  options,
}) => {
  const [, , helper] = useField(name);

  const handleOnChange = React.useCallback<ChipOnChange>(
    (e) => {
      helper.setValue(e.value);
    },
    [helper],
  );

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '15px',
          fontWeight: 500,
          mb: '15px',
        }}
      >
        {label}
      </Typography>

      <ChipOptionsV2
        options={options}
        name={name}
        defaultValue={String(defaultValue)}
        onChange={handleOnChange}
      />
    </Box>
  );
};

type Values = StepOneFormValue & StepTwoFormValue & StepThreeFormValue;

type Props = {
  method?: Method;
  initialValues?: Values;
  values: Values;
};

export const Form3: React.FC<Props> = ({ method = 'insert', initialValues, values }) => {
  const venueState = useAppSelector((state) => state.venue.value);

  const subscriptionAccess = useSubscriptionAccess();

  const { fOptions } = React.useMemo(
    () => ({
      fOptions: followerOptionsV2(subscriptionAccess),
    }),
    [subscriptionAccess],
  );

  const noPremiumAccess = React.useMemo(
    () => !subscriptionAccess?.includes('PREMIUM_ACCESS_CONTROL'),
    [subscriptionAccess],
  );

  return (
    <>
      <Box mb={6}>
        <MinimumFollowersOptions defaultValue={values.minFollowers || 0} options={fOptions} />
        {initialValues &&
          method === 'update' &&
          initialValues.minFollowers !== values.minFollowers && <AttentionError />}
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: 500,
            mb: '15px',
          }}
        >
          Posting requirements
        </Typography>
        <ReqRadioGroup
          aria-labelledby='radio-group-requirement-label'
          name='radio-group-requirement'
        />
        {initialValues &&
          method === 'update' &&
          values.requirements !== initialValues.requirements && <AttentionError />}
      </Box>

      <Box mt={5}>
        {isAllowCallToConfirm(values.requirements !== 'noRequirement', values.category) && (
          <OfferCallToConfirm />
        )}

        {initialValues &&
          method === 'update' &&
          Boolean(initialValues.confirmWithCall) !== Boolean(values.confirmWithCall) && (
            <AttentionError />
          )}

        {values.requirements !== 'noRequirement' && (
          <>
            <Typography
              sx={{
                fontSize: '22px',
                fontWeight: 600,
                mb: '27px',
              }}
            >
              Optional
            </Typography>
            <SideBySide ratio={6}>
              <CustomBox>
                <label htmlFor='txt-dresscode' className='offer-form-label'>
                  Dresscode
                </label>
                <FormikTextField
                  className='offer-input-field'
                  id='txt-dresscode'
                  name='dressCode'
                  placeholder='Ex. casual, elegant, sportive, etc.'
                />
              </CustomBox>

              <CustomBoxTrip>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'10px'}
                  justifyContent={'space-between'}
                  flexWrap={'nowrap'}
                >
                  <label htmlFor='txt-tip' className='offer-form-label'>
                    Suggested tip
                  </label>
                  {noPremiumAccess && (
                    <Stack spacing={1} direction='row' gap={0} alignItems={'center'} mb={1}>
                      <StarterIcon />
                      <Box
                        component={Link}
                        sx={{
                          fontSize: '15px',
                          fontWeight: '500',
                          textDecoration: 'underline',
                          color: '#000',
                        }}
                        to='/plans'
                      >
                        Premium Feature
                      </Box>
                    </Stack>
                  )}
                </Box>
                <FormikNumberField
                  className='offer-input-field'
                  id='txt-tip'
                  name='suggestedTip'
                  placeholder='0'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        {venueState?.currency && <CurrencySign currency={venueState?.currency} />}
                      </InputAdornment>
                    ),
                  }}
                  disabled={noPremiumAccess}
                />
                {initialValues &&
                  method === 'update' &&
                  Number(values.suggestedTip) !== Number(initialValues.suggestedTip) && (
                    <AttentionError />
                  )}
              </CustomBoxTrip>
            </SideBySide>
          </>
        )}
      </Box>
    </>
  );
};

const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;

const CustomBoxTrip = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;
