import * as React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { Offer } from '../../../graphql/API';
import { getOfferAccessValue } from '../../../utils/offerAccess';

type AccessProps = {
  data: Offer;
};

const Access: React.FC<AccessProps> = ({ data }) => {
  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <Typography component='div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ color: '#37BB90', mr: 4, fontSize: '15px', fontWeight: 500 }}>Lifestyle</Box>
        <Box sx={{ fontSize: '15px', fontWeight: 400 }}>
          {getOfferAccessValue(data.selectMembersAccess)}
        </Box>
      </Typography>

      <Typography component='div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ color: '#955CCC', mr: 4, fontSize: '15px', fontWeight: 500 }}>
          Beautypass Select
        </Box>
        <Box sx={{ fontSize: '15px', fontWeight: 400 }}>
          {getOfferAccessValue(data.selectMembersAccess)}
        </Box>
      </Typography>
    </Stack>
  );
};

export default Access;
