import React from 'react';

import { Box, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';

import styled from '@emotion/styled';

import FormikNumberField from '../../../components/Formiks/NumberField/NumberField';
import Icons from '../../../themes/icons';
import SideBySide from './SideBySide';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    minWidth: '340px',
  },
}));

export const AvailableSpots = () => {
  return (
    <Box>
      <Typography sx={{ fontSize: '22px', fontWeight: 600, mb: '24px' }}>
        Redemption Settings
      </Typography>
      <SideBySide ratio={6}>
        <AvailableBox>
          <Box display={'flex'} alignItems={'center'} gap={'10px'} mb={2}>
            <Label htmlFor='txt-spots'>Spots available per day</Label>
            <HtmlTooltip
              placement='top'
              arrow
              title={
                <Box>
                  <Typography sx={{ fontSize: '15px', color: '#000', p: '16px' }}>
                    The number of times this offer can be claimed per day
                  </Typography>
                </Box>
              }
            >
              <Icons.InfoIcon />
            </HtmlTooltip>
          </Box>
          <CustomBoxAvailble>
            <FormikNumberField
              id='txt-spots'
              name='spots'
              placeholder=''
              className='offer-input-field'
            />
          </CustomBoxAvailble>
        </AvailableBox>
      </SideBySide>
    </Box>
  );
};

const AvailableBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomBoxAvailble = styled.div`
  position: relative;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;

const Label = styled.label`
  color: #000;
  font-size: 15px;
  font-weight: 500;
`;
