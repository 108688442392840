import * as React from 'react';

import { Box, Stack } from '@mui/material';

import { Formik } from 'formik';

import Button from '../../../../components/Button/Button';
import {
  StepFourFormValue,
  StepOneFormValue,
  StepThreeFormValue,
  StepTwoFormValue,
  stepFourSchema,
} from '../schema';
import { Form4 } from './Forms/Form4';
import './style.css';

type Values = StepFourFormValue & StepThreeFormValue & StepTwoFormValue & StepOneFormValue;

type ManageAccessProps = { data: Values; handleNext: (values: Values) => void };

export const Step4: React.FC<ManageAccessProps> = ({ data, handleNext }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={stepFourSchema}
      onSubmit={(values) => {
        handleNext(values);
      }}
    >
      {({ values, handleSubmit }) => {
        return (
          <Box
            sx={{ paddingTop: '32px', paddingBottom: '64px' }}
            component={'form'}
            onSubmit={handleSubmit}
          >
            <Stack spacing={6} sx={{ width: '100%' }}>
              <Form4 values={values} />
              <Button fullWidth variant='contained' type='submit' sx={{ px: '5rem', py: '1rem' }}>
                Create offer
              </Button>
            </Stack>
          </Box>
        );
      }}
    </Formik>
  );
};
