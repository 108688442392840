import * as React from 'react';
import {
  CalendarProps,
  Calendar as ReactBigCalendar,
  SlotInfo,
  dayjsLocalizer,
} from 'react-big-calendar';

import { Box, Button, LinearProgress, styled } from '@mui/material';

import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import { venueUpdateVenueApi } from '../../../api/venue/venueUpdateVenueApi';
import { OfferStatus } from '../../../graphql/API';
import { fetchVenue } from '../../../redux/features/venue/venueSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { colors } from '../../../themes/default.theme';
import Toolbar from '../Toolbar/Toolbar';
import '../styles/calendar.css';
import MonthEvent from './MonthEvent';
import { mapToCalendarEvent } from './mapper';

const djLocalizer = dayjsLocalizer(dayjs);

const Calendar = styled(ReactBigCalendar)(() => ({
  '.rbc-event': {
    backgroundColor: 'unset',
  },
}));

const OfferPreviewCalendar: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const data = useAppSelector((state) => state.offer.value || []);
  const dispatch = useAppDispatch();
  const { closedDates: closedDatesInString, id: venueId } =
    useAppSelector((state) => state.venue.value) || {};

  const venueClosedDates = React.useMemo(
    () =>
      closedDatesInString?.reduce<Date[]>(
        (acc, cur) => (cur ? [...acc, dayjs(cur).toDate()] : acc),
        [],
      ) || [],
    [closedDatesInString],
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [closedDates, setClosedDates] = React.useState<Date[]>([]);

  React.useEffect(() => {
    if (venueClosedDates) {
      setClosedDates(venueClosedDates);
    }
  }, [venueClosedDates]);

  const { components } = React.useMemo<Partial<CalendarProps>>(
    () => ({
      components: {
        toolbar: Toolbar,
        month: { event: MonthEvent },
      },
      showAllEvents: true,
    }),
    [],
  );

  const dayPropGetter = React.useCallback(
    (date: Date) => {
      const isClosed = closedDates.some((closedDate) => closedDate.getTime() === date.getTime());
      const wasClosed = venueClosedDates.some(
        (closedDate) => closedDate.getTime() === date.getTime(),
      );

      return {
        style: {
          background: isClosed
            ? colors.brandRed + 'aa'
            : wasClosed
              ? colors.brandRed + '33'
              : undefined,
        },
      };
    },
    [closedDates, venueClosedDates],
  );

  const onSelectSlot = React.useCallback(
    (slotInfo: SlotInfo) =>
      setClosedDates((prev) => {
        const selectedDates = [...(slotInfo.slots as Date[])];

        return prev.some((date) =>
          selectedDates.some((selectedDate) => selectedDate.getTime() === date.getTime()),
        )
          ? prev.filter(
              (date) =>
                !selectedDates.some((selectedDate) => selectedDate.getTime() === date.getTime()),
            )
          : [...prev, ...selectedDates];
      }),
    [],
  );

  const eventPropGetter = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any, startDate: Date | string) => {
      const isClosed = closedDates.some((closedDate) =>
        dayjs(closedDate).isSame(dayjs(startDate), 'day'),
      );

      return {
        style: {
          background:
            isClosed || event.status === OfferStatus.inactive ? colors.grey400 : undefined,
          borderColor:
            isClosed || event.status === OfferStatus.inactive ? colors.grey400 : undefined,
          color: isClosed || event.status === OfferStatus.inactive ? colors.white : undefined,
          textDecoration:
            isClosed || event.status === OfferStatus.inactive ? 'line-through' : undefined,
        },
      };
    },
    [closedDates],
  );

  const { events } = React.useMemo<Partial<CalendarProps>>(
    () => ({
      events: mapToCalendarEvent(
        data.filter((offer) => !offer.private),
        date,
      ),
    }),
    [date, data],
  );

  const buttonEnabled = React.useMemo(
    () =>
      !closedDates.every((closedDate) =>
        venueClosedDates.some(
          (venueClosedDate) => venueClosedDate.getTime() === closedDate.getTime(),
        ),
      ) ||
      !venueClosedDates.every((venueClosedDate) =>
        closedDates.some((closedDate) => venueClosedDate.getTime() === closedDate.getTime()),
      ),
    [closedDates, venueClosedDates],
  );

  const buttonOnClick = async () => {
    try {
      if (venueId) {
        setIsLoading(true);
        await venueUpdateVenueApi({
          id: venueId,
          closedDates: closedDates.map((date) => dayjs(date).format('YYYY-MM-DD')),
        });
        enqueueSnackbar('Dates updated successfully', {
          variant: 'success',
        });
        setIsLoading(false);
        if (onClose) onClose();
        dispatch(fetchVenue());
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong while updating please try again later', {
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ height: '100%' }}>
      {isLoading && <LinearProgress />}
      <Calendar
        selectable
        onSelecting={() => false}
        onSelectSlot={onSelectSlot}
        style={{ height: '95%' }}
        dayPropGetter={dayPropGetter}
        events={events}
        localizer={djLocalizer}
        views={['month']}
        popup
        onNavigate={(dt) => {
          setDate(dt);
        }}
        components={components}
        eventPropGetter={eventPropGetter}
      />
      <Box textAlign={'right'} my={1}>
        <Button
          disabled={!buttonEnabled || isLoading}
          disableElevation
          color='primary'
          variant='contained'
          onClick={buttonOnClick}
        >
          Update closed dates
        </Button>
      </Box>
    </Box>
  );
};

export default OfferPreviewCalendar;
