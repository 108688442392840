import { pick } from 'lodash';
import * as yup from 'yup';

import { Venue, VenueUpdateVenueInput } from '../../../../graphql/API';

const websiteSchema = yup
  .string()
  .matches(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/, 'Invalid website URL');

export const schema = yup.object({
  name: yup.string().required(),
  address1: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  country: yup.string().required(),
  description: yup.string().max(800),
  website: websiteSchema,
});

export const makeFormValue = (venue: Venue | undefined): VenueUpdateVenueInput | undefined =>
  venue
    ? {
        id: venue.id,
        ...pick(venue, [
          'name',
          'phone',
          'address1',
          'address2',
          'city',
          'stateProvince',
          'postalCode',
          'country',
          'website',
          'description',
          'contactFirstname',
          'contactLastname',
          'contactPosition',
          'formattedAddress',
          'countryCode',
          'location.lat',
          'location.lon',
        ]),
      }
    : undefined;
