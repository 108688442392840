import React, { useCallback } from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';

import Icons from '../../themes/icons';

type Variant = 'success' | 'warning' | 'error';

type Props = {
  notiVariant: Variant;
} & CustomContentProps;

const stackColors: Record<string, Record<string, string>> = {
  success: {
    bgColor: '#CCF6E0',
    buttonBgColor: '#A5CEB8',
  },
  warning: {
    bgColor: '#FEF3CD',
    buttonBgColor: '#DFE4B7',
  },
  error: {
    bgColor: '#FED2D2',
    buttonBgColor: '#E4B8B7',
  },
};

const stackVariantIcons: Record<string, React.ReactNode> = {
  success: <Icons.TickIcon />,
  warning: <Icons.InfoIcon />,
  error: <Icons.CrossIcon />,
};

export const NotiAlertV2 = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { id, message, variant } = props;

  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Box
        sx={{
          borderRadius: '6px',
          backgroundColor: stackColors[variant]?.bgColor,
          py: '13px',
          px: '15px',
          display: 'flex',
          alginItems: 'center',
          justifyContent: 'space-between',
          gap: '130px',
          minWidth: '700px',
          maxWidth: '1000px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '22px' }}>
          {stackVariantIcons[variant]}
          <Typography
            component={'span'}
            sx={{ fontSize: '15px', fontWeight: 500, fontFamily: 'Poppins', color: '#111' }}
          >
            {message}
          </Typography>
        </Box>
        <IconButton
          sx={{ backgroundColor: stackColors[variant]?.buttonBgColor, p: '5px' }}
          onClick={handleDismiss}
        >
          <Icons.CloseIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </Box>
    </SnackbarContent>
  );
});
