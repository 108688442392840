import * as React from 'react';

import { Grid, Typography } from '@mui/material';

type LabelDetailProps = {
  label: string | React.ReactElement;
  description?: string | null | React.ReactElement;
  alignItems?: string;
};

const LabelDetail: React.FC<LabelDetailProps> = ({ label, description, alignItems = 'start' }) => {
  return (
    <Grid container sx={{ marginBottom: '20px', display: 'flex', alignItems }}>
      <Grid
        item
        xs={4}
        xl={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: '15px',
            color: '#848484',
            fontWeight: 400,
            fontFamily: 'Poppins'
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} xl={8}>
        <Typography
          sx={{
            color: '#111',
            fontSize: '15px',
            fontWeight: 400,
            fontFamily: 'Poppins'
          }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LabelDetail;
