import { ModelType } from '../../../graphql/API';
import { Plan } from '../../../pages/SubscriptionPlans/types';
import { ACCESS } from '../../../redux/features/subscription/access';
import { ChipOption } from '../../ChipOptions/ChipOptions';
import Tooltip, { PlusIcon, StarterIcon, TooltipContent } from './ToolTip';
import { PartialVenueListUsersFilter } from './types';

const makeOptionDisable = (
  disabled: boolean,
  plan: Plan,
  icon: ChipOption['icon'],
): Omit<ChipOption, 'label' | 'value'> | undefined => {
  return {
    disabled,
    icon,
    ...(disabled && {
      tooltip: {
        Component: Tooltip,
        props: {
          placement: 'top',
          title: <TooltipContent plan={plan} />,
        },
      },
    }),
  };
};

export const followerOptions: (
  subscriptionAccess: ACCESS[] | undefined,
  isBrand?: boolean,
) => ChipOption[] = (subscriptionAccess, isBrand = false) => [
  { label: '0+', value: '', disabled: isBrand },
  { label: '1k+', value: '1000', disabled: isBrand },
  { label: '5k+', value: '5000', disabled: isBrand },
  { label: '10k+', value: '10000', disabled: isBrand },
  {
    label: '25k+',
    value: '25000',
    ...makeOptionDisable(
      !subscriptionAccess?.includes('FILTER_25K_FOLLOWERS') || isBrand,
      'starter',
      <StarterIcon />,
    ),
  },
  {
    label: '50k+',
    value: '50000',
    ...makeOptionDisable(
      !subscriptionAccess?.includes('FILTER_50K_FOLLOWERS') || isBrand,
      'plus',
      <PlusIcon />,
    ),
  },
];

export const followerOptionsV2: (subscriptionAccess: ACCESS[] | undefined) => ChipOption[] = (
  subscriptionAccess,
) => [
  { label: '0+', value: '0' },
  { label: '1k+', value: '1000' },
  { label: '5k+', value: '5000' },
  { label: '10k+', value: '10000' },
  {
    label: '25k+',
    value: '25000',
    ...makeOptionDisable(
      !subscriptionAccess?.includes('FILTER_25K_FOLLOWERS'),
      'starter',
      <StarterIcon />,
    ),
  },
  {
    label: '50k+',
    value: '50000',
    ...makeOptionDisable(
      !subscriptionAccess?.includes('FILTER_50K_FOLLOWERS'),
      'plus',
      <PlusIcon />,
    ),
  },
];

export const modelOptions = (isBrand = false): ChipOption[] => [
  { label: 'All', value: '', disabled: isBrand },
  { label: 'Agency', value: ModelType.model, disabled: isBrand },
  { label: 'Freelancer', value: ModelType.freelancer, disabled: isBrand },
];

export const memberCategoryOptions = (isBrand = false): ChipOption[] => [
  { label: 'All', value: '', disabled: isBrand },
  { label: 'Lifestyle', value: 'true', disabled: isBrand },
  { label: 'Beautypass Select', value: 'false', disabled: isBrand },
];

export const verifiedOptions: (
  subscriptionAccess: ACCESS[] | undefined,
  isBrand?: boolean,
) => ChipOption[] = (subscriptionAccess, isBrand = false) => [
  { label: 'All', value: '', disabled: isBrand },
  {
    label: 'Verified',
    value: 'true',
    ...makeOptionDisable(
      !subscriptionAccess?.includes('COMPLETE_USER_PROFILES') || isBrand,
      'starter',
      <StarterIcon />,
    ),
  },
  { label: 'Not verified', value: 'false', disabled: isBrand },
];

type FilterOutput = Record<string, (value: unknown) => PartialVenueListUsersFilter>;

type MakeDefaultValue = Record<string, (value: PartialVenueListUsersFilter | undefined) => string>;

export const makeDefaultValue: MakeDefaultValue = {
  follower: (f) => String(f?.minFollowers ?? ''),
  cost: (f) => String(f?.inviteCost ?? ''),
  model: (f) => String(f?.modelType ?? ''),
  instagramVerified: (f) => {
    if (f?.instagram !== undefined) {
      return f.instagram ? 'true' : 'false';
    }
    return '';
  },
  lifestyle: (f) => {
    if (f?.lifestyle !== undefined) {
      return f.lifestyle ? 'true' : 'false';
    }
    return '';
  },
};

export const makeFiltersOutput = (
  filters: PartialVenueListUsersFilter | undefined,
): FilterOutput => ({
  follower: (value) => ({
    ...filters,
    minFollowers: value ? Number(value) : undefined,
  }),
  cost: (value) => ({
    ...filters,
    inviteCost: value ? Number(value) : undefined,
  }),
  model: (value) => ({
    ...filters,
    modelType: value ? (value as ModelType) : undefined,
  }),
  instagramVerified: (value) => ({
    ...filters,
    instagram: value ? value === 'true' : undefined,
  }),
  market: (value) => ({
    ...filters,
    market: !value,
  }),
  lifestyle: (value) => ({
    ...filters,
    lifestyle: value ? value === 'true' : undefined,
  }),
});
