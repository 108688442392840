import * as React from 'react';
import { AspectRatio } from 'react-aspect-ratio';

import { Avatar, Box, styled } from '@mui/material';

import { useSnackbar } from 'notistack';

import UploadButton from './UploadButton';
import useProfileMutation from './useProfileMutation';

const CoverUploadButton = styled(UploadButton)(({ theme }) => ({
  color: 'black',
  background: 'white',
  paddingInline: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.custom.colors.brandLightGrey,
  },
}));

type CoverPhotoProps = {
  label: string;
  src: string;
};

const CoverPhoto: React.FC<CoverPhotoProps> = ({ label, src }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { uploadMutation } = useProfileMutation();

  const [uploadPreview, setUploadPreview] = React.useState<string>();

  const handleFileUpload = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const file = event.target.files ? event.target.files[0] : undefined;

      if (file) {
        const dataUrl = URL.createObjectURL(file);
        setUploadPreview(dataUrl);

        uploadMutation.mutate(file);
      }
    },
    [setUploadPreview, uploadMutation],
  );

  const handleCoverPhoto = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      enqueueSnackbar('To change your cover photo, contact business@beautypass.app', {
        variant: 'warning',
      });
    },
    [enqueueSnackbar],
  );

  return (
    <AspectRatio ratio='4/3'>
      <Avatar variant='rounded' alt={label} src={uploadPreview ?? src} />
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: theme.spacing(1.5),
          right: theme.spacing(1.5),
        })}
      >
        <CoverUploadButton
          loading={uploadMutation.isPending}
          onFileUpload={handleFileUpload}
          enableImagePicker={false}
          onClick={handleCoverPhoto}
        >
          Cover photo
        </CoverUploadButton>
      </Box>
    </AspectRatio>
  );
};

export default CoverPhoto;
