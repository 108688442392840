import * as React from 'react';

import { Box, Divider, Stack, Switch, SwitchProps, Typography } from '@mui/material';

import { isEqual } from 'lodash';

import useSubscriptionAccess from '../../../hooks/useSubscriptionAccess';
import { collabsDefaultFilters } from '../../../pages/Collabs/reducer';
import Button from '../../Button/Button';
import { ChipOptionsProps } from '../../ChipOptions/ChipOptions';
import ChipOptionContainer from './ChipOptionContainer';
import {
  followerOptions,
  makeDefaultValue,
  makeFiltersOutput,
  memberCategoryOptions,
  modelOptions,
  verifiedOptions,
} from './options';
import { PartialVenueListUsersFilter } from './types';
import { useAppSelector } from '../../../redux/hooks';
import { OfferCategory } from '../../../graphql/API';
import { extractSubscriptionPlan } from '../../../pages/SubscriptionPlans/common/helper';
import { VENUE_MANUAL_TAGS } from '../../../utils/venue-tags';

export type CollabsFiltersProps = {
  filters?: PartialVenueListUsersFilter;
  onChange?: (filters: PartialVenueListUsersFilter | undefined) => void;
  onClose?: () => void;
};

type SwitchOnChange = Exclude<SwitchProps['onChange'], undefined>;
type ChipOnChange = ChipOptionsProps['onChange'];

const CollabsFilters: React.FC<CollabsFiltersProps> = ({
  filters: defaultFilters,
  onChange,
  onClose,
}) => {
  const subscriptionAccess = useSubscriptionAccess();
  const venueState = useAppSelector(state => state.venue.value);
  const { subscription } = useAppSelector((state) => state.subscription.value);

  const subscriptionPlan = React.useMemo(() => {
    const [plan] = extractSubscriptionPlan(subscription?.sku ?? '');

    return plan;
  }, [subscription]);

  const venueIsBrand = venueState?.tags?.includes(VENUE_MANUAL_TAGS.BrandPlans) && !subscriptionPlan;

  const [filters, setFilters] = React.useState<PartialVenueListUsersFilter | undefined>(
    defaultFilters,
  );

  const onApply = () => {
    if (onChange) onChange(filters);
    if (onClose) onClose();
  };

  const { fOptions, vOptions } = React.useMemo(
    () => ({
      fOptions: followerOptions(subscriptionAccess, venueIsBrand),
      vOptions: verifiedOptions(subscriptionAccess, venueIsBrand),
    }),
    [subscriptionAccess, venueIsBrand],
  );

  const handleOnChange = React.useCallback<ChipOnChange>(
    (e) => {
      const outputFilters = makeFiltersOutput(filters)[e.name](e.value);
      setFilters(outputFilters);
    },
    [filters],
  );

  const handleSwitchOnChange = React.useCallback<SwitchOnChange>(
    (_e, checked) => {
      const outputFilters = makeFiltersOutput(filters)['market'](checked);
      setFilters(outputFilters);
    },
    [filters],
  );

  const onResetFilters = () => {
    setFilters(collabsDefaultFilters);
  };

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography fontWeight='bold' fontSize={'15px'}>
          Show me members in all market
        </Typography>
        <Switch
          onChange={handleSwitchOnChange}
          checked={!filters?.market}
          value=''
          inputProps={{ 'aria-label': 'market switch' }}
          disabled={venueIsBrand}
        />
      </Box>

      <Divider sx={{ my: 4 }} />

      <Stack spacing={4}>
        <ChipOptionContainer
          defaultValue={makeDefaultValue['follower'](filters)}
          name='follower'
          label='Followers'
          options={fOptions}
          onChange={handleOnChange}
        />

        <ChipOptionContainer
          defaultValue={makeDefaultValue['lifestyle'](filters)}
          name='lifestyle'
          label='Member category'
          options={memberCategoryOptions(venueIsBrand)}
          onChange={handleOnChange}
          eyeIconForMemberCategory
        />

        <ChipOptionContainer
          defaultValue={makeDefaultValue['model'](filters)}
          name='model'
          label='Models'
          options={modelOptions(venueIsBrand)}
          onChange={handleOnChange}
        />

        <ChipOptionContainer
          verifiedIcon
          defaultValue={makeDefaultValue['instagramVerified'](filters)}
          name='instagramVerified'
          label='Verified on Instagram'
          options={vOptions}
          onChange={handleOnChange}
        />
      </Stack>

      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={3}>
        <Button sx={{ textDecoration: 'underline' }} onClick={onResetFilters} disabled={venueIsBrand}>
          Reset Filters
        </Button>
        <Box display={'flex'} alignItems={'center'} gap={'1rem'}>
          <Button variant='outlined' sx={{ padding: '6px 20px' }} onClick={onClose} disabled={venueIsBrand}>
            Cancel
          </Button>
          <Button
            variant='contained'
            sx={{ padding: '6px 20px' }}
            onClick={onApply}
            disabled={isEqual(defaultFilters, filters) || venueIsBrand}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CollabsFilters;
