import React from 'react';

import { Box, InputAdornment, Stack, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { useField } from 'formik';
import { upperFirst } from 'lodash';

import { CurrencySign } from '../../../../../components/CurrencySign/CurrencySign';
import FormikNumberField from '../../../../../components/Formiks/NumberField/NumberField';
import SelectOption, {
  OptionItem,
} from '../../../../../components/Formiks/SelectOption/SelectOption';
import FormikTextField from '../../../../../components/Formiks/TextField/TextField';
import { OfferCategory } from '../../../../../graphql/API';
import { useAppSelector } from '../../../../../redux/hooks';
import { Method } from '../../../OfferForm';
import { AttentionError } from '../../AttentionError';
import SideBySide from '../../SideBySide';
import OfferPhoto from '../../formiks/OfferPhoto/OfferPhoto';
import { OFFER_CATEGORIES, OFFER_FOOD_CATEGORIES, StepOneFormValue } from '../../schema';

const offerCategoryOptions = OFFER_CATEGORIES.filter(
  (x) =>
    x !== OfferCategory.unknown &&
    x !== OfferCategory.event &&
    x !== OfferCategory.brand &&
    x !== OfferCategory.wellness &&
    x !== OfferCategory.fashion,
).map<OptionItem>((x) => ({
  label: upperFirst(x),
  value: x,
}));

const offerSubcategoryOptions = OFFER_FOOD_CATEGORIES.map<OptionItem>((x) => ({
  label: upperFirst(x),
  value: x,
}));

type Values = StepOneFormValue;

type Props = {
  method?: Method;
  initialValues?: Values;
  values: Values;
};

export const Form1: React.FC<Props> = ({ method = 'insert', initialValues, values }) => {
  const venueState = useAppSelector((state) => state.venue.value);

  const [titleField, , titleFieldHelper] = useField('title');

  React.useEffect(() => {
    const formattedValue = titleField.value.charAt(0).toUpperCase() + titleField.value.slice(1);
    titleFieldHelper.setValue(formattedValue);
  }, [titleField.value, titleFieldHelper]);

  return (
    <Box>
      <Typography color={`#111111`} fontSize={`24px`} fontWeight={`600`} marginBottom={'18px'}>
        {values.type === 'private'
          ? 'Collab details'
          : values.category === OfferCategory.event
            ? 'Event details'
            : 'Offer details'}
      </Typography>
      <Stack spacing={6} sx={{ width: '100%' }}>
        <OfferPhoto />

        {values.type === 'public' && values.category !== OfferCategory.event && (
          <Box>
            <CustomBox>
              <Label htmlFor='slt-offer-category' className='offer-form-label'>
                Offer category
              </Label>
              <SelectOption
                sx={{ border: '1px solid transparent', padding: '5px 0' }}
                id='slt-offer-category'
                name='category'
                options={offerCategoryOptions}
              />
            </CustomBox>
            {initialValues && method === 'update' && initialValues.category !== values.category && (
              <AttentionError />
            )}
          </Box>
        )}
        {values.category === 'food' && (
          <CustomBox>
            <Label htmlFor='slt-offer-sub-category' className='offer-form-label'>
              Offer subcategory
            </Label>
            <SelectOption
              sx={{ border: '1px solid transparent', padding: '5px 0' }}
              id='slt-offer-sub-category'
              name='subCategory'
              options={offerSubcategoryOptions}
            />
            {initialValues &&
              method === 'update' &&
              values.category &&
              initialValues.subCategory !== values.subCategory && <AttentionError />}
          </CustomBox>
        )}

        <CustomBox>
          <Label htmlFor='txt-title' className='offer-form-label'>
            {values.category === OfferCategory.event ? 'Event title' : 'Offer title'}
          </Label>
          <FormikTextField
            id='txt-title'
            name='title'
            placeholder={
              values.category === OfferCategory.event
                ? 'Ex. Fashion week launch event'
                : 'Ex. dinner, haircut and brushing'
            }
            className='offer-input-field'
          />
        </CustomBox>
        <CustomBox>
          <Label htmlFor='txt-desc' className='offer-form-label'>
            Description
          </Label>
          <FormikTextField
            id='txt-desc'
            name='description'
            placeholder={
              values.category === OfferCategory.event
                ? 'Ex. Join us at the exclusive Fashion Week launch event for our brand. There will be a DJ, open bar, and lots of surprises!'
                : 'Ex. 1 lunch item from the menu and drink of choice'
            }
            multiline
            rows={5}
            className='offer-input-field'
          />
        </CustomBox>

        <SideBySide shouldFlexEnd>
          <CustomBox>
            <Label htmlFor='txt-offer-value' className='offer-form-label'>
              Offer value
            </Label>
            <FormikNumberField
              id='txt-offer-value'
              name='offerValue'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    {venueState?.currency && <CurrencySign currency={venueState?.currency} />}
                  </InputAdornment>
                ),
              }}
              className='offer-input-field'
            />
          </CustomBox>
        </SideBySide>
      </Stack>
    </Box>
  );
};

const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  fieldset {
    border: 1px solid #e6e6e6;
  }
`;

const Label = styled.label`
  color: #000;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 8px;
`;
