import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SxProps, Theme, Typography } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

type GoBackProps = {
  to?: string;
  title?: string;
  sx?: SxProps<Theme>;
};

export const GoBackV2: React.FC<GoBackProps> = ({ title, to, sx }) => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const linkProps = React.useMemo<IconButtonProps<React.ElementType>>(() => {
    if (to) {
      const props: IconButtonProps<typeof Link> = {
        component: Link,
        to: String(to),
      };

      return props;
    }

    return {
      onClick: handleClick,
    } as IconButtonProps<'button'>;
  }, [to, handleClick]);

  return (
    <IconButton
      aria-label='back'
      color='primary'
      {...linkProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        borderRadius: '8px',
        ...sx,
      }}
    >
      <ArrowBackIosIcon />
      {title && (
        <Typography component='span' sx={{ fontSize: '18px' }}>
          {title}
        </Typography>
      )}
    </IconButton>
  );
};
