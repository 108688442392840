import * as React from 'react';

import { Box, Grid, GridProps, LinearProgress } from '@mui/material';

import { useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';

import { venueGetOfferStatisticsApi } from '../../api/offers/venueGetOfferStatisticsApi';
import Button from '../../components/Button/Button';
import ChipOptions from '../../components/ChipOptions/ChipOptions';
import { CalendarDialog } from '../../components/Dialogs';
import { CalendarIcon } from '../../components/Icons';
import PageTitle from '../../components/PageTitle/PageTitle';
import { OfferStatus, VenueListOffersFilter } from '../../graphql/API';
import { useListOfferQuery } from '../../hooks/reactQuery/useOfferQuery';
import { useFilter } from '../../hooks/useFilter';
import { useAppSelector } from '../../redux/hooks';
import AddOffer from './components/AddOffer';
import OfferCard from './components/OfferCard';
import OfferCardSkeleton from './components/OfferCardSkeleton';

const commonGridProps = (key: string): GridProps => ({
  key,
  item: true,
  xs: 6,
  md: 4,
  lg: 3,
  xl: 2.4,
});

const Offer: React.FC = () => {
  const venue = useAppSelector((state) => state.venue.value);
  const { showModal } = useModal();

  const handleCalendarOpen = React.useCallback(
    () => showModal(CalendarDialog, { title: 'Calendar' }),
    [showModal],
  );

  const [filters, setFilters] = useFilter<Partial<VenueListOffersFilter>>({
    status: OfferStatus.active,
  });

  const { data, isFetching, isLoading } = useListOfferQuery(filters);
  const { data: offerStatisticsData, isFetching: venueGetOfferStatisticsIsFetching } = useQuery({
    queryKey: ['venueGetOfferStatistics', venue?.id || ''],
    queryFn: async ({ queryKey }) => {
      const [, venueId] = queryKey as [string, string];

      return venueGetOfferStatisticsApi({ venueID: Number(venueId) });
    },
    enabled: Boolean(venue?.id),
  });

  return (
    <Box
      padding={4}
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box>
        <PageTitle
          title='Your offer'
          sideComponent={
            <Button startIcon={<CalendarIcon />} onClick={handleCalendarOpen}>
              Calendar
            </Button>
          }
        />

        <Box mt={2}>
          <ChipOptions
            name='offerStatus'
            options={[
              {
                label: `Active (${offerStatisticsData?.totalActiveOffers ?? '0'})`,
                value: OfferStatus.active,
              },
              {
                label: `In Review (${offerStatisticsData?.totalPendingOffers ?? '0'})`,
                value: OfferStatus.pending,
              },
              {
                label: `Cancelled (${offerStatisticsData?.totalCancelledOffers ?? '0'})`,
                value: OfferStatus.cancelled,
              },
              {
                label: `Ended (${offerStatisticsData?.totalInactiveOffers ?? '0'})`,
                value: OfferStatus.inactive,
              },
            ]}
            defaultValue={filters.status ?? ''}
            onChange={({ value: v }) => setFilters({ status: v ? (v as OfferStatus) : undefined })}
          />
        </Box>

        <Box mt={4}>
          {((!isLoading && isFetching) || venueGetOfferStatisticsIsFetching) && (
            <LinearProgress sx={{ mb: 1 }} />
          )}
        </Box>
      </Box>

      <Box sx={{ overflowY: 'auto', height: 'calc(100vh - 265px)' }}>
        {isLoading ? (
          <Grid container spacing={2}>
            {[...Array(4)].map((_, i) => (
              <Grid {...commonGridProps(`grid-item-${i}`)}>
                <OfferCardSkeleton />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid {...commonGridProps('add-offer')}>
              <AddOffer />
            </Grid>
            {data.map((x) => (
              <Grid {...commonGridProps(x.id)}>
                <OfferCard data={x} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default Offer;
