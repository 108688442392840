import React from 'react';

import styled from '@emotion/styled';

import { TermsAndConditionsCompleteData } from '../../../hooks/useTermsAndConditions';
import Button from '../../Button/Button';
import { LegalContent } from '../../LegalContent/LegalContent';
import BaseDialog, { BaseDialogProps } from '../Base';

type Props = {
  termsData: TermsAndConditionsCompleteData;
  onAgreeTerms: (val: boolean) => void;
} & BaseDialogProps;

export const TermsAndConditionDialog: React.FC<Props> = ({
  termsData: { isLoading, terms, updateDate },
  onAgreeTerms,
  onClose,
  ...rest
}) => {
  const [isTermsRead, setIsTermsRead] = React.useState(false);

  const onTermsReadHandle = () => {
    setIsTermsRead(true);
  };

  const onAgreeHandle = () => {
    onAgreeTerms(isTermsRead);
  };

  return (
    <BaseDialog
      width='940px'
      minHeight='700px'
      titleComponent={<ModalTitle>Updates to our Terms</ModalTitle>}
      padding='14px 10px'
      showCloseIcon={false}
      {...rest}
    >
      <TermsContainer>
        {isLoading && <p>Loading...</p>}
        {terms && (
          <LegalContent
            content={terms}
            updateDate={updateDate || ''}
            isTermsRead={isTermsRead}
            onTermsRead={onTermsReadHandle}
          />
        )}
      </TermsContainer>
      <Footer>
        <Button
          variant='outlined'
          fullWidth
          sx={{ borderRadius: '8px', py: 1.5 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          fullWidth
          sx={{ borderRadius: '8px', py: 1.5 }}
          disabled={isLoading || !isTermsRead}
          onClick={onAgreeHandle}
        >
          I Agree
        </Button>
      </Footer>
    </BaseDialog>
  );
};

const ModalTitle = styled.h1`
  color: #000;
  font-size: 28px;
  font-weight: 500;
  margin: 0 0 10px 0;
  padding: 0;
`;

const TermsContainer = styled.div`
  border-radius: 6px;
  background-color: #f8f8f8;
  padding: 25px;
  height: 85%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 6px;
    margin: 5px 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #121212;
    border-radius: 8px;
  }
`;

const Footer = styled.div`
  margin-top: 34px;
  width: 75%;

  display: flex;
  align-items: center;
  gap: 18px;
`;
