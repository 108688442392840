import { Offer } from '../../graphql/API';
import { executeGql, processGqlResult } from '../../utils/gql-utils';

const venueGetOfferQuery = /* GraphQL */ `
  query venueGetOffer($id: String!) {
    venueGetOffer(id: $id) {
      availableDays
      category
      confirmWithCall
      description
      dressCode
      endDate
      endTime
      id
      includeSelfInPost
      includeServiceOrProductInPost
      instagramTag
      marketCurrency
      marketID
      marketName
      minFollowers
      offerValue
      picture {
        large
        medium
        thumbnail
      }
      postOnInstagramFeed
      postOnInstagramReels
      postOnInstagramStories
      postOnInstagramStories3x
      premium
      private
      spots
      startDate
      startTime
      status
      subCategory
      suggestedTip
      timeZoneId
      title
      venueID
      venueName
      waitTime
      lifestyleMembersAccess
      selectMembersAccess
      address
      startDateTime
    }
  }
`;

export const venueGetOfferApi = async (id: string): Promise<Offer> => {
  const result = await executeGql<Offer>(venueGetOfferQuery, { id });
  return processGqlResult<Offer>('venueGetOffer', result);
};
